import React from 'react';
import PropTypes from 'prop-types';

export default function FormField({ children }) {
  return <div className="col-xs-12 col-sm-8">{children}</div>;
}

FormField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};
