import React from 'react';
import PropTypes from 'prop-types';

export default class ProductAssetEdit extends React.Component {
  constructor(props) {
    super(props);
  }

  handleEdit() {
    this.props.handleEdit(this.props.id);
  }

  render() {
    return (
      <a className={this.props.editCls} onClick={this.handleEdit.bind(this)}>
        <i className={this.props.iconCls} />
      </a>
    );
  }
}

ProductAssetEdit.propTypes = {
  id: PropTypes.string.isRequired,
  editCls: PropTypes.string,
  iconCls: PropTypes.string,
  confirmStr: PropTypes.string,
  handleEdit: PropTypes.func.isRequired,
};

ProductAssetEdit.defaultProps = {
  id: '',
  editCls: 'edit-product-asset',
  iconCls: 'fa fa-pencil',
  handleEdit() {},
};
