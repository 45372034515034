import React from 'react';
import PropTypes from 'prop-types';
import Editor from '../Editor';
import FormRow from '../FormRow';
import FormLabel from '../FormLabel';
import FormField from '../FormField';
import AssetControl from '../formFieldComponents/AssetControl';
import { readAsset } from '../../productdb/AssetService';
import ColorPicker from '../ColorPicker';
import { copyBlockAligmentOptions } from './richContentOptions';

export default class PrimaryCopyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      content: {
        title: props.initialValue.title,
        copy: props.initialValue.copy,
        // Attributes transformed to camelCase while reading JSON data
        image_id: props.initialValue.imageId,
        text_color: props.initialValue.textColor,
        background_color: props.initialValue.backgroundColor,
        copy_block_alignment: props.initialValue.copyBlockAlignment,
      },
      image: {},
    };
    this.handleImageAddition = this.handleImageAddition.bind(this);
    this.handleImageRemoval = this.handleImageRemoval.bind(this);
  }

  componentDidMount() {
    const { imageId } = this.props.initialValue;
    if (!imageId || imageId === '') {
      return;
    }
    readAsset(this.props.productCategory, imageId)
      .then(json => {
        this.setState({
          ...this.state,
          image: json,
        });
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  handleTitle(event) {
    this.setState({ content: { ...this.state.content, title: event.target.value } });
  }

  handleEditorChange(event) {
    this.setState({ content: { ...this.state.content, copy: event.target.getContent() } });
  }

  handleImageAddition(data) {
    const imageId = data && data.id ? data.id : '';
    this.setState({ content: { ...this.state.content, image_id: imageId } });
  }

  handleImageRemoval() {
    this.setState({ content: { ...this.state.content, image_id: '' } });
  }

  handleColorPicker(event, attrName) {
    this.setState({ content: { ...this.state.content, [attrName]: event.color } });
  }

  handleSelect(event, attrName) {
    this.setState({ content: { ...this.state.content, [attrName]: event.target.value } });
  }

  render() {
    return (
      <React.Fragment>
        <input
          type="hidden"
          name={this.props.fieldName}
          id={this.props.fieldId}
          value={JSON.stringify(this.state.content, null, 2)}
        />
        <FormRow>
          <FormLabel htmlFor="primary_copy_title">Title</FormLabel>
          <FormField>
            <input
              type="text"
              className="form-control"
              id="primary_copy_title"
              value={this.state.content.title}
              onChange={event => this.handleTitle(event)}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="primary_copy_copy">Copy</FormLabel>
          <FormField>
            <Editor
              initialValue={this.state.content.copy}
              onChange={event => this.handleEditorChange(event)}
              id="primary_copy_copy"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="primary_copy_image" l>
            Image
          </FormLabel>
          <FormField>
            <AssetControl
              colCls="col-sm-3"
              fileType="generic_product_image"
              assetPath={this.props.assetPath}
              attributeName="rich_content_primary_copy"
              type="asset"
              appendCallback={this.handleImageAddition}
              removeCallback={this.handleImageRemoval}
              initialValue={this.state.image}
              thumbs={[this.state.image && this.state.image.thumb_url]}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="primary_copy_copy_block_alignment">Copy Block Alignment</FormLabel>
          <FormField>
            <select
              className="form-control"
              value={this.state.content.copy_block_alignment}
              onChange={event => this.handleSelect(event, 'copy_block_alignment')}
            >
              {copyBlockAligmentOptions()}
            </select>
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="primary_copy_text_color">Text Color</FormLabel>
          <ColorPicker
            value={this.state.content.text_color}
            onChangeComplete={event => this.handleColorPicker(event, 'text_color')}
            id="primary_copy_text_color"
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="primary_copy_background_color">Background Color</FormLabel>
          <ColorPicker
            value={this.state.content.background_color}
            onChangeComplete={event => this.handleColorPicker(event, 'background_color')}
            id="primary_copy_background_color"
          />
        </FormRow>
      </React.Fragment>
    );
  }
}

PrimaryCopyContainer.propTypes = {
  initialValue: PropTypes.shape({
    title: PropTypes.string,
    copy: PropTypes.string,
    imageId: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    copyBlockAlignment: PropTypes.string,
  }),
  assetPath: PropTypes.string.isRequired,
  productCategory: PropTypes.string.isRequired,
};

PrimaryCopyContainer.defaultProps = {
  initialValue: {
    title: '',
    copy: '',
    image: '',
    textColor: '',
    backgroundColor: '',
    copyBlockAlignment: '',
  },
};
