import React from 'react';
import PropTypes from 'prop-types';
import './APITokenField.sass';

function confirmTokenAction(currentToken, e, callback) {
  e.preventDefault();
  const message = 'Are you sure? The current token will no longer be valid.';
  if (!currentToken || confirm(message)) {
    callback(e);
  }
}

function renderDeleteButton(user, disabled, deleteToken) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      className="btn btn-default btn-primary btn-sm"
      disabled={disabled}
      onClick={e => {
        confirmTokenAction(user.author_token, e, deleteToken);
      }}
    >
      Delete API token
    </a>
  );
}

export default function APITokenField(props) {
  if (!props.user) {
    return null;
  }
  return (
    <div>
      <div className="apitokenfield__token">
        {props.user.author_token ? props.user.author_token : 'No API token defined'}
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        className="btn btn-default btn-primary btn-sm apitokenfield__token"
        disabled={props.disabled}
        onClick={e => {
          confirmTokenAction(props.user.author_token, e, props.createNewToken);
        }}
      >
        Create new API token
      </a>
      {props.user.author_token
        ? renderDeleteButton(props.user, props.disabled, props.deleteToken)
        : null}
    </div>
  );
}

APITokenField.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    author_token: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  createNewToken: PropTypes.func.isRequired,
  deleteToken: PropTypes.func.isRequired,
};

APITokenField.defaultProps = {
  user: undefined,
  disabled: false,
};
