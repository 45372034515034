import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import FieldControlBase from './formFieldComponents/FieldControlBase';
import FormFieldWrapper from './formFieldComponents/FormFieldWrapper';
import AssetControl from './formFieldComponents/AssetControl';

export default class ProductAssetControl extends FieldControlBase {
  constructor(props) {
    super(props);
  }

  assetControlProps() {
    return {
      initialValue: this.props.initialValue,
      type: this.props.type,
      productId: this.props.productId,
      attributeName: this.props.attributeName,
      localizable: this.props.localizable,
      thumbs: this.props.thumbs,
      assetPath: this.props.assetPath,
      fileType: this.props.fileType,
      placeholder: this.props.placeholder,
      ajaxLoader: this.props.ajaxLoader,
      colCls: this.props.colCls,
      assetPropertiesName: this.props.assetPropertiesName,
      edit: this.props.edit,
    };
  }

  renderReadOnly() {
    return (
      <FormFieldWrapper type="asset">
        <AssetControl {...this.assetControlProps()} renderStatus="read-only" />
      </FormFieldWrapper>
    );
  }

  renderRegular() {
    return (
      <FormFieldWrapper type="asset">
        <AssetControl {...this.assetControlProps()} renderStatus="regular" />
      </FormFieldWrapper>
    );
  }

  renderLocalizable() {
    return this.renderRegular();
  }
}

const propTypes = _.clone(FieldControlBase.propTypes);
propTypes.initialValue = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.arrayOf(PropTypes.object),
]);
propTypes.shadowedValue = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.arrayOf(PropTypes.object),
]);
propTypes.type = PropTypes.string.isRequired;
propTypes.productId = PropTypes.string.isRequired;
propTypes.thumbs = PropTypes.arrayOf(PropTypes.string);
propTypes.assetPath = PropTypes.string.isRequired;
propTypes.fileType = PropTypes.string.isRequired;
propTypes.placeholder = PropTypes.string;
propTypes.ajaxLoader = PropTypes.string;
propTypes.assetPropertiesName = PropTypes.string;
propTypes.edit = PropTypes.bool;
ProductAssetControl.propTypes = propTypes;

ProductAssetControl.defaultProps = {
  attributeName: '',
  initialValue: [],
  thumbs: [],
  placeholder: '',
  ajaxLoader: '',
  assetPropertiesName: '',
  edit: true,
};
