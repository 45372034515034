import React from 'react';
import './spinner.sass';

export default function Spinner() {
  return (
    <div className="cockpit-spinner">
      <i className="fa fa-spinner spinner-icon" aria-hidden="true" />
    </div>
  );
}
