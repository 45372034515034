import React from 'react';
import { mount } from 'enzyme';

import APITokenField from '../APITokenField';

const user = { id: 'some' };
const userWithAPI = { id: 'some', author_token: 'apitoken' };
const createNewToken = jest.fn();
const deleteToken = jest.fn();

const originalConfirm = window.confirm;

beforeAll(() => {
  window.confirm = () => true;
});

afterAll(() => {
  window.confirm = originalConfirm;
});

describe('user without api information', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(
      <APITokenField
        user={user}
        disabled={false}
        createNewToken={createNewToken}
        deleteToken={deleteToken}
      />,
    );
  });

  test('shows a no key message', () => {
    expect(
      wrapper
        .find('div')
        .at(1)
        .text(),
    ).toMatch(/no.*api.*token/i);
  });

  test('has only one link element', () => {
    expect(wrapper.find('a').length).toEqual(1);
  });

  test('has the create element', () => {
    expect(wrapper.find('a').text()).toMatch(/new.*token/i);
  });

  test('a click calls the create callback', () => {
    wrapper.find('a').simulate('click');

    expect(createNewToken).toHaveBeenCalledTimes(1);
  });
});

describe('user with api information', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(
      <APITokenField
        user={userWithAPI}
        disabled
        createNewToken={createNewToken}
        deleteToken={deleteToken}
      />,
    );
  });

  test('shows the API key', () => {
    expect(
      wrapper
        .find('div')
        .at(1)
        .text(),
    ).toMatch(/apitoken/);
  });

  test('has two link elements', () => {
    expect(wrapper.find('a').length).toEqual(2);
  });

  test('has the delete element', () => {
    expect(
      wrapper
        .find('a')
        .at(1)
        .text(),
    ).toMatch(/delete/i);
  });

  test('a click on the delete element calls the delete callback', () => {
    wrapper
      .find('a')
      .at(1)
      .simulate('click');

    expect(deleteToken).toHaveBeenCalledTimes(1);
  });
});
