const clientOptions = {
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
  },
};

const basePath = '/products/document_imports/steps/';

export default function deleteStepDocuments(stepId) {
  const opts = { ...clientOptions, ...{ method: 'POST' } };
  return fetch(`${basePath}${encodeURIComponent(stepId)}/delete_documents`, opts).then(response =>
    response.json(),
  );
}
