import React from 'react';
import { Link } from 'react-router-dom';
import BatchesTable from './BatchesTable';
import BatchCreateButton from './BatchCreateButton';
import { fetchBatches } from './batchesClient';

export default class BatchesTableComponentWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      batches: null,
    };
    this.updateBatchDisplay = this.updateBatchDisplay.bind(this);
    this.reloadBatches = this.reloadBatches.bind(this);
  }

  componentDidMount() {
    this.fetchBatchesList();
  }

  updateBatchDisplay(updatedBatch) {
    this.setState(prevState => {
      if (!prevState.batches) {
        return null;
      }

      const newBatches = prevState.batches.map(batch => {
        if (updatedBatch && updatedBatch.id === batch.id) {
          return updatedBatch;
        }
        return batch;
      });

      return {
        batches: newBatches,
      };
    });
  }

  fetchBatchesList() {
    fetchBatches().then(batches => {
      this.setState({
        batches,
      });
    });
  }

  reloadBatches() {
    this.fetchBatchesList();
  }

  render() {
    return (
      <div>
        <div className="row navigation-section">
          <div className="col-sm-12">
            <BatchCreateButton reloadBatches={this.reloadBatches} />
            &nbsp;
            <Link to="manage_locale_mappings" className="btn btn-default">
              Manage Locale Mappings
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <BatchesTable
              batches={this.state.batches}
              updateBatchDisplay={this.updateBatchDisplay}
            />
          </div>
        </div>
      </div>
    );
  }
}
