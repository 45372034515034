import React from 'react';

const TIMEOUT = 5000;

export default class EnqueuedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.getAllQueues();
    this.state = {
      queues: [
        { display: 'Index', name: 'index', value: 0 },
        { display: 'Import', name: 'import', value: 0 },
      ],
    };
  }

  getAllQueues() {
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      credentials: 'include',
    };

    const fetchQueuesFromServer = new Promise(resolve => {
      const response = fetch('/queues/all', options);
      resolve(response);
    });

    fetchQueuesFromServer.then(data => data.json()).then(data => this.handleSuccess(data));

    setTimeout(() => {
      this.getAllQueues();
    }, TIMEOUT);
  }

  handleSuccess(data) {
    this.state.queues = this.state.queues.map(q => {
      q.value = data[q.name] === undefined ? 0 : data[q.name];
      return q;
    });
    this.setState(this.state);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.queues.map(queue => (
          <li key={queue.name}>
            <div className="navbar-text jobs">
              Open Jobs ({queue.display}
              ): {queue.value}
            </div>
          </li>
        ))}
      </React.Fragment>
    );
  }
}
