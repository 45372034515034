import React from 'react';
import PropTypes from 'prop-types';
import { createBatch } from './batchesClient';

export default class BatchCreateButton extends React.Component {
  constructor() {
    super();
    this.createNewBatch = this.createNewBatch.bind(this);
    this.state = {};
  }

  createNewBatch() {
    this.setState({
      requestStatus: 'pending',
    });
    createBatch().then(() => {
      this.setState({ requestStatus: null });
      this.props.reloadBatches();
    });
  }

  render() {
    return (
      <button
        className="btn btn-default"
        onClick={this.createNewBatch}
        disabled={this.state.requestStatus === 'pending'}
      >
        Start new import
      </button>
    );
  }
}

BatchCreateButton.propTypes = {
  reloadBatches: PropTypes.func.isRequired,
};
