import React from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

export default class ProductAssetAppend extends React.Component {
  constructor(props) {
    super(props);
  }

  handleAppend(asset) {
    this.props.handleAppend(asset);
  }

  render() {
    return (
      <Dropzone
        className="dropzone"
        onDrop={this.handleAppend.bind(this)}
        multiple={false}
        id={this.props.attributeName}
      >
        <div className={`${this.props.className} ${this.props.colCls}`}>
          <img src={this.props.placeholder} alt={this.props.imageAlt} />
          <i className={this.props.iconCls} />
        </div>
      </Dropzone>
    );
  }
}

ProductAssetAppend.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  iconCls: PropTypes.string,
  colCls: PropTypes.string,
  attributeName: PropTypes.string.isRequired,
  handleAppend: PropTypes.func.isRequired,
};

ProductAssetAppend.defaultProps = {
  className: 'product-asset',
  colCls: 'col-sm-3',
  placeholder: '',
  imageAlt: 'placeholder',
  iconCls: 'fa fa-plus-circle',
  attributeName: '',
  handleAppend() {},
};
