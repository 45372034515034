import React from 'react';
import PropTypes from 'prop-types';

export default class ProductAssetMove extends React.Component {
  constructor(props) {
    super(props);
  }

  handleMove(up) {
    const prevIndex = this.props.index;
    const newIndex = up
      ? Math.min(this.props.index + 1, this.props.total)
      : Math.max(this.props.index - 1, 0);
    if (prevIndex !== newIndex) {
      this.props.handleMove(prevIndex, newIndex);
    }
  }

  render() {
    return (
      <div className={this.props.moveCls}>
        <a className={`${this.props.moveCls}-left`} onClick={this.handleMove.bind(this, false)}>
          <i className={this.props.iconLeftCls} />
        </a>
        <a className={`${this.props.moveCls}-right`} onClick={this.handleMove.bind(this, true)}>
          <i className={this.props.iconRightCls} />
        </a>
      </div>
    );
  }
}

ProductAssetMove.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  moveCls: PropTypes.string,
  iconLeftCls: PropTypes.string,
  iconRightCls: PropTypes.string,
  handleMove: PropTypes.func.isRequired,
};

ProductAssetMove.defaultProps = {
  moveCls: 'move-product-asset',
  iconLeftCls: 'fa fa-chevron-left',
  iconRightCls: 'fa fa-chevron-right',
};
