/**
 * Usage:
 * Please fill this module with reusable static methods !
 */

export default class UtilityHelpers {
  static numberToCurrency(number, currency = '') {
    const _price = parseFloat(number).toFixed(2);
    const locale = document.documentElement.lang;
    let _currency = '€';
    if (locale === 'en-GB' || currency === 'GBP') {
      _currency = '£';
      return `${_currency} ${_price}`;
    }
    return `${_price} ${_currency}`;
  }

  static sortMethod(attr, decreasing = false) {
    const attributes = !Array.isArray(attr) ? [attr] : attr;
    return function sort(a, b) {
      for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];
        if (a[attribute] < b[attribute]) {
          return decreasing ? 1 : -1;
        }
        if (a[attribute] > b[attribute]) {
          return decreasing ? -1 : 1;
        }
      }
      return 0;
    };
  }

  static randomString(length = 8) {
    // 10 digits + 26 letters = 36 characters
    return Math.random()
      .toString(36)
      .substr(2, length);
  }

  static parentNode(target, parentLevel) {
    let node = target;
    for (let i = 0; i < parentLevel; i++) {
      node = node.parentNode;
    }
    return node;
  }

  static activateElement(element, defaultClassName) {
    if (element.className.indexOf('active') === -1) {
      element.className += ' active';
    } else {
      element.className = defaultClassName;
    }
  }

  static dasherize(string) {
    return string.replace(/[ –_/&]/g, '-');
  }

  static underscore(string) {
    return string.replace(/[ -/–&]/g, '_');
  }

  static humanize(string) {
    return string
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_–\s]+/g, ' ')
      .replace(/^[a-z]/, m => m.toUpperCase());
  }

  static capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static camelCase(string) {
    if (string && /^[a-zA-Z0-9]*$/.test(string)) return string; // Already camelCase
    const stringArray = this.underscore(string)
      .toLowerCase()
      .split('_');
    return stringArray
      .slice(0, 1)
      .concat(stringArray.slice(1).map(word => this.capitalize(word)))
      .join('');
  }

  static snakeCase(string) {
    return this.underscore(
      string.replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, ''),
    );
  }

  static deserialize(queryStr = location.search) {
    const params = {};
    if (queryStr && queryStr.replace) {
      queryStr.replace(
        /([^=?]+)=([^&]*)&?/g,
        (match, key, value) => (params[decodeURIComponent(key)] = decodeURIComponent(value)),
      );
    }
    return params;
  }
}
