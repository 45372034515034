const clientOptions = {
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
  },
};

const basePath = '/products/document_imports/batches/';

export function fetchBatches() {
  return fetch(`${basePath}`, clientOptions).then(response => response.json());
}

export function showBatch(id) {
  return fetch(`${basePath}${encodeURIComponent(id)}`, clientOptions).then(response =>
    response.json(),
  );
}

export function createBatch() {
  const opts = { ...clientOptions, ...{ method: 'POST' } };
  return fetch(`${basePath}`, opts).then(response => response.json());
}

export function pauseBatch(batch) {
  const opts = { ...clientOptions, ...{ method: 'POST' } };
  return fetch(`${basePath}${encodeURIComponent(batch.id)}/pause`, opts).then(response =>
    response.json(),
  );
}

export function unpauseBatch(batch) {
  const opts = { ...clientOptions, ...{ method: 'POST' } };
  return fetch(`${basePath}${encodeURIComponent(batch.id)}/unpause`, opts).then(response =>
    response.json(),
  );
}

export function showBatchSteps(batchId) {
  return fetch(`${basePath}${encodeURIComponent(batchId)}/steps`, clientOptions).then(response =>
    response.json(),
  );
}
