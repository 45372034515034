import React from 'react';
import PropTypes from 'prop-types';

export default class ReadOnlySelectList extends React.Component {
  render() {
    return (
      <ul className="read-only">
        {this.props.value.map((value, index) => {
          const optionsIndex = this.props.options.map(option => option[1]).indexOf(value);
          if (this.props.options[optionsIndex]) {
            return (
              <li key={index} className="">
                {this.props.options[optionsIndex][0]}
              </li>
            );
          }
        })}
      </ul>
    );
  }
}

ReadOnlySelectList.propTypes = {
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  options: PropTypes.array,
};

ReadOnlySelectList.defaultProps = {
  value: [],
  options: [],
};
