import React from 'react';

export const copyBlockAligmentOptions = () => {
  const options = [
    '',
    'top-left',
    'top-right',
    'top-center',
    'middle-left',
    'middle-right',
    'middle-center',
    'bottom-left',
    'bottom-right',
    'bottom-center',
  ];

  return (
    <React.Fragment>
      {options.map(option => (
        <option key={option} value={option}>
          {option === '' ? 'Please select' : option}
        </option>
      ))}
    </React.Fragment>
  );
};

export const textAlignmentOptions = () => {
  const options = ['', 'left', 'center', 'right', 'justify'];

  return (
    <React.Fragment>
      {options.map(option => (
        <option key={option} value={option}>
          {option === '' ? 'Please select' : option}
        </option>
      ))}
    </React.Fragment>
  );
};
