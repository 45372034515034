import React from 'react';
import { ExcelImport, Changeset, AttributeChange } from '../../../apiClients/specs';

interface SpecsChangesetProps {
  excelImport: ExcelImport;
}
export default function SpecsChangeset({ excelImport }: SpecsChangesetProps): JSX.Element {
  function renderAttributeChange(
    id: string,
    row: number,
    attributeChange?: AttributeChange[],
  ): JSX.Element[] | null {
    if (!attributeChange || attributeChange.length === 0) {
      return null;
    }
    return attributeChange.map(ac => (
      <tr key={[id, ac.field].join('-')}>
        <td>{`${id.split('-')[0]}...`}</td>
        <td>{row}</td>
        <td>{ac.field}</td>
        <td>{ac.old}</td>
        <td>{ac.new}</td>
      </tr>
    ));
  }

  function renderChangeRows(changeset: Changeset): JSX.Element[] {
    const pc = renderAttributeChange(changeset.id, changeset.row, changeset.productChanges);
    const tc = renderAttributeChange(changeset.id, changeset.row, changeset.translationChanges);
    const sc = renderAttributeChange(changeset.id, changeset.row, changeset.specChanges);
    let rows: JSX.Element[] = [];
    if (pc !== null) {
      rows = rows.concat(pc);
    }
    if (tc !== null) {
      rows = rows.concat(tc);
    }
    if (sc !== null) {
      rows = rows.concat(sc);
    }
    return rows;
  }

  if (excelImport.changesets.length === 0) {
    return <div>No changes pending.</div>;
  }

  return (
    <table className="table table-striped table-hover table-condensed">
      <thead>
        <tr>
          <th>ID</th>
          <th>Row</th>
          <th>Attribute</th>
          <th>Old Value</th>
          <th>New Value</th>
        </tr>
      </thead>
      <tbody>
        {excelImport.changesets.map(cs => {
          return renderChangeRows(cs);
        })}
      </tbody>
    </table>
  );
}
