import React from 'react';
import PropTypes from 'prop-types';

export default class FormFieldKeepEmptyElement extends React.Component {
  renderElement() {
    return this.props.keepEmptyFieldName && this.props.keepEmptyFieldId;
  }

  render() {
    let result = null;
    if (this.renderElement()) {
      result = (
        <div className="nil-override">
          <input
            type="checkbox"
            className="nil-override-checkbox"
            name={this.props.keepEmptyFieldName}
            id={this.props.keepEmptyFieldId}
            checked={this.props.keepEmptyFieldValue}
            onChange={this.props.handleChange}
          />
          <label htmlFor={this.props.keepEmptyFieldId}>Keep empty</label>
        </div>
      );
    }
    return result;
  }
}

FormFieldKeepEmptyElement.propTypes = {
  keepEmptyFieldName: PropTypes.string,
  keepEmptyFieldId: PropTypes.string,
  keepEmptyFieldValue: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};
