import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import FieldControlBase from './formFieldComponents/FieldControlBase';
import FormFieldWrapper from './formFieldComponents/FormFieldWrapper';
import ReadOnlySelectList from './formFieldComponents/ReadOnlySelectList';

export default class SelectControl extends FieldControlBase {
  constructor(props) {
    super(props);
  }

  isIdField() {
    return this.props.attributeName.endsWith('_id');
  }

  getDefaultInput() {
    if (!this.props.options) {
      return <input type="hidden" name={this.props.fieldName} defaultValue="" />;
    }
  }

  getOptions() {
    const options = this.props.options.slice();
    options.unshift(['Nothing selected', '']);
    return this.props.includeBlank ? options : this.props.options.slice();
  }

  getSelectField() {
    if (this.props.options) {
      return (
        <select
          className="col-sm-5"
          name={this.props.fieldName}
          value={this.props.value || this.state.attributeValue || ''}
          onChange={this.handleChange.bind(this)}
        >
          {this.getOptions().map((option, index) => (
            <option key={index} value={option[1]}>
              {option[0]}
            </option>
          ))}
        </select>
      );
    }
  }

  handleChange(event) {
    this.setState({ attributeValue: event.target.value });
    if (this.props.onChange) this.props.onChange(event);
  }

  attributeValueOutput() {
    if (this.isIdField()) {
      return this.valueForId();
    }
    return this.state.attributeValue;
  }

  valueForId() {
    let matching = [];
    if (this.props.options) {
      matching = this.props.options.find(selected => selected[1] === this.state.attributeValue);
    }
    return matching ? matching[0] : this.state.attributeValue;
  }

  renderReadOnly() {
    return (
      <FormFieldWrapper>
        <ReadOnlySelectList value={[this.state.attributeValue]} options={this.props.options} />
      </FormFieldWrapper>
    );
  }

  renderRegular() {
    return (
      <FormFieldWrapper>
        <div>
          {this.getDefaultInput()}
          {this.getSelectField()}
        </div>
      </FormFieldWrapper>
    );
  }

  renderLocalizable() {
    return this.renderRegular();
  }
}

const propTypes = _.clone(FieldControlBase.propTypes);
// eslint-disable-next-line max-len
propTypes.options = PropTypes.arrayOf(
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
);
propTypes.onChange = PropTypes.func;
SelectControl.propTypes = propTypes;
