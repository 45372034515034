import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

/*
 * This component is a wrapper for elements that should be inserted
 * at a different point in the DOM.
 */
export default class Portal extends React.Component {
  componentDidMount() {
    this.componentDidUpdate();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.portalContainer) {
      if (nextProps.parent && nextProps.parent !== this.props.parent) {
        this.portalContainer.appendTo(nextProps.parent);
      } else if (this.props.parent && !nextProps.parent) {
        this.portalContainer.detach();
      }
    }
  }

  componentDidUpdate() {
    ReactDOM.render(this.getComponent(), this.getContainer());
  }

  componentWillUnmount() {
    this.portalContainer.remove();
    delete this.portalContainer;
  }

  getContainer() {
    if (!this.portalContainer) {
      this.portalContainer = jQuery('<div class="portal-container" />').appendTo(
        this.props.parent || document.body,
      );
    }
    return this.portalContainer[0];
  }

  getComponent() {
    return <div>{this.props.children}</div>;
  }

  render() {
    return null;
  }
}

Portal.propTypes = {
  parent: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
