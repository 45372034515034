const host =
  process.env.NODE_ENV === 'production'
    ? 'https://partnershop.prod.shureweb.eu'
    : 'http://localhost:3003';

function filenameFromContentDisposition(contentDisposition: string | null): string {
  const defaultFilename = 'excel.xlsx';
  if (!contentDisposition) {
    return defaultFilename;
  }

  const parts = contentDisposition.split(';');
  const filename = parts
    .map(part => {
      const keyValue = part && (part.split('=') as Array<string>);
      if (keyValue.length > 1 && keyValue[0].trim() === 'filename') {
        const f = keyValue[1]
          .trim()
          .replace(/^"/, '')
          .replace(/"$/, '');
        return f;
      }
      return null;
    })
    .find(f => !!f);

  return filename || defaultFilename;
}

async function downloadSpecsFile(category: string, locale: string): Promise<void> {
  const result = await fetch(`${host}/excel/specs/${category}`, {
    headers: {
      'Accept-Language': locale,
    },
  });
  const blob = await result.blob();
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filenameFromContentDisposition(result.headers.get('Content-Disposition'));
  link.dispatchEvent(new MouseEvent('click'));
  link.remove();
}

export interface ExcelImport {
  productCategory: string;
  locale: string;
  changesets: Changeset[];
  originalPayload;
}

export interface Changeset {
  id: string;
  row: number;
  productChanges?: AttributeChange[];
  translationChanges?: AttributeChange[];
  specChanges?: AttributeChange[];
}

export interface AttributeChange {
  field: string;
  old: string | number;
  new: string;
}

function buildChangeset(json): ExcelImport {
  const excelImport: ExcelImport = {
    productCategory: json.product_category,
    locale: json.locale,
    changesets:
      (json.changesets &&
        json.changesets.map(cs => {
          const changeset: Changeset = {
            id: cs.id,
            row: cs.row,
          };
          if (cs.product_changes) {
            changeset.productChanges = cs.product_changes.map(pc => {
              const productChanges: AttributeChange = {
                field: pc.field,
                old: pc.old,
                new: pc.new,
              };
              return productChanges;
            });
          }
          if (cs.translation_changes) {
            changeset.translationChanges = cs.translation_changes.map(tc => {
              const translationChanges: AttributeChange = {
                field: tc.field,
                old: tc.old,
                new: tc.new,
              };
              return translationChanges;
            });
          }
          if (cs.spec_changes) {
            changeset.specChanges = cs.spec_changes.map(sc => {
              const specChanges: AttributeChange = {
                field: sc.field,
                old: sc.old,
                new: sc.new,
              };
              return specChanges;
            });
          }
          return changeset;
        })) ||
      [],
    originalPayload: json,
  };
  return excelImport;
}

async function handleError(response: Response): Promise<void> {
  let error = new Error(response.statusText);
  try {
    const json = await response.json();
    if (json && json.errors && json.errors.length > 0) {
      error = new Error(json.errors[0].detail);
    }
  } catch {
    // nothing
  }
  throw error;
}

async function uploadSpecsFile(file: File): Promise<ExcelImport> {
  const formData = new FormData();
  formData.append('file', file);
  const response = await fetch(`${host}/excel/specs/upload`, {
    method: 'POST',
    body: formData,
  });
  if (!response.ok) {
    await handleError(response);
  }
  const j = await response.json();
  const excelImport = buildChangeset(j);
  return excelImport;
}

async function commitChangeset(excelImport: ExcelImport): Promise<void> {
  const response = await fetch(`${host}/excel/specs/commit`, {
    method: 'POST',
    body: JSON.stringify(excelImport.originalPayload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    await handleError(response);
  }
}

export { downloadSpecsFile, uploadSpecsFile, commitChangeset };
