import React from 'react';
import PropTypes from 'prop-types';

export default class FormFieldCopyElement extends React.Component {
  showCopyElement() {
    return (
      (!this.props.currentValue || this.props.currentValue === '') &&
      this.props.shadowedValue &&
      this.props.shadowedValue !== ''
    );
  }

  render() {
    let result;
    if (this.showCopyElement()) {
      result = (
        <div className="attribute-copy" onClick={this.props.reset}>
          <i className="fa fa-clipboard" />
        </div>
      );
    } else {
      result = <div />;
    }
    return result;
  }
}

FormFieldCopyElement.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shadowedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reset: PropTypes.func.isRequired,
};
