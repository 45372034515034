const clientOptions = {
  credentials: 'same-origin',
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  method: 'GET',
};

const LocaleMappingsBase = '/products/document_imports/locale_mappings';

export function getLocaleMappings() {
  return fetch(`${LocaleMappingsBase}`, clientOptions).then(res => res.json());
}

export function getLocales() {
  return fetch(`${LocaleMappingsBase}/locales`, clientOptions).then(res => res.json());
}

export function updateLocaleMapping(data) {
  return fetch(`${LocaleMappingsBase}`, {
    ...clientOptions,
    method: 'POST',
    body: JSON.stringify(data),
  }).then(res => res.json());
}

export function deleteLocaleMapping(id) {
  return fetch(`${LocaleMappingsBase}/${id}`, {
    ...clientOptions,
    method: 'DELETE',
  });
}
