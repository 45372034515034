import React from 'react';
import PropTypes from 'prop-types';

export default class FormFieldWrapper extends React.Component {
  getColumnCls() {
    return ['asset', 'external_content'].indexOf(this.props.type) !== -1
      ? 'col-sm-10'
      : 'col-xs-12 col-sm-8';
  }

  render() {
    return <div className={this.getColumnCls()}>{this.props.children}</div>;
  }
}

FormFieldWrapper.propTypes = {
  children: PropTypes.element,
  type: PropTypes.string,
};

FormFieldWrapper.defaultProps = {
  type: 'field',
};
