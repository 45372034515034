import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../../../util/history';
import '../../shared/navigation.sass';
import ValueSetsPage from './ValueSetsPage';
import ValueSetsNewPage from './ValueSetsNewPage';
import ValueSetsEditPage from './ValueSetsEditPage';

export default function BatchesRouter(): JSX.Element {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/products/value_sets" component={ValueSetsPage} />
        <Route exact path="/products/value_sets/new" component={ValueSetsNewPage} />
        <Route exact path="/products/value_sets/:id/values" component={ValueSetsEditPage} />
      </Switch>
    </Router>
  );
}
