import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import FileUploader from '../../../shure/FileUploader';
import UtilityHelpers from '../../../shure/UtilityHelpers';
import ProductAssetAppend from './ProductAssetAppend';
import ProductAssetDelete from './ProductAssetDelete';
import ProductAssetEdit from './ProductAssetEdit';
import ProductAssetMove from './ProductAssetMove';
import GeneratedFormModal from '../../shared/GeneratedFormModal';

import ajaxLoader from './ajax-loader.gif';
import placeholder from './placeholder.png';

export default class AssetControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appending: false,
      moving: false,
      values: this.formatInitialValues(this.props.initialValue),
      editingItem: undefined,
      modalTitle: '',
      modalDisabled: false,
    };
    this.appendAssetToState = this.appendAssetToState.bind(this);
    this.removeAssetFromState = this.removeAssetFromState.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleAppend = this.handleAppend.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        values: this.formatInitialValues(this.props.initialValue),
      });
    }
  }

  formatInitialValues(initialValue) {
    const values = Array.isArray(initialValue) ? initialValue.slice() : [initialValue].slice();
    if (this.props.thumbs.length) {
      values.forEach((value, index) => {
        const thumb = this.props.thumbs[index] || null;
        if (value && thumb) {
          value.imageUrls = { thumb };
        }
      });
    }
    return values;
  }

  formatValues(data, items) {
    return data.map(object => {
      const index = items.map(item => item.id).indexOf(object.id);
      return items[index];
    });
  }

  updateValueState(data, item) {
    if (data) {
      const values = this.state.values.slice();
      values.forEach(value => {
        if (value.id === item.id) {
          value.title = data.title;
          value.description = data.description;
        }
      });
      this.setState({ values });
    }
  }

  ajaxLoaderMovingCls(state, index) {
    return state - 1 === index ? '' : 'hidden';
  }

  ajaxLoaderAppendingCls(state) {
    return state ? '' : 'hidden';
  }

  assetCls(state, index) {
    return (this.props.type === 'asset' && this.state.appending) || state - 1 === index
      ? 'hidden'
      : '';
  }

  startAppending() {
    this.setState({ appending: true });
  }

  stopAppending() {
    this.setState({ appending: false });
  }

  handleAppend(file) {
    const fileUploader = new FileUploader(this.props.assetPath);
    this.startAppending();
    fileUploader.uploadFile(file[0], this.props.fileType, this.appendAssetToState);
  }

  handleMove(index, newIndex) {
    const items = this.state.values.slice();
    const [item] = items.splice(index, 1);
    items.splice(newIndex, 0, item);
    const ids = items.map(el => el.id);
    this.setState({ moving: index + 1 });
    jQuery
      .ajax({
        method: 'PATCH',
        url: `${this.props.assetPath}/sort`,
        data: {
          file_type: this.props.fileType,
          ids,
        },
      })
      .done(data => {
        this.setState({ values: this.formatValues(data, items), moving: false });
      })
      .fail(() => {
        this.setState({ moving: false });
        if (this.props.errorMessage) {
          alert(this.props.errorMessage);
        }
      });
  }

  handleDelete(id) {
    const path = this.props.assetPath.split('/');
    path.pop();
    const url = `${path.join('/')}/${id}`;
    const fileUploader = new FileUploader(url);
    fileUploader.deleteFile(this.removeAssetFromState);
  }

  handleEdit(id) {
    const item = this.state.values.find(value => value.id === id);
    if (item) {
      this.setState({
        editingItem: _.extend({ id }, item),
        modalTitle: `Edit ${this.props.assetPropertiesName}`,
      });
    }
  }

  handleModalCancel() {
    this.setState({ editingItem: undefined });
  }

  handleModalChange(attrName, value) {
    const editingItem = _.extend({}, this.state.editingItem);
    editingItem[attrName] = value;
    this.setState({ editingItem });
  }

  handleModalSubmit(e) {
    e.preventDefault();
    this.setState({ modalDisabled: true });
    const item = this.state.editingItem;
    if (item) {
      const url = `${this.props.assetPath}/edit/${item.id}`;
      this.setState({
        modalDisabled: false,
      });
      jQuery
        .ajax({
          method: 'PATCH',
          url,
          data: {
            file_type: this.props.fileType,
            title: item.title,
            description: item.description,
          },
          dataType: 'json',
        })
        .done(data => {
          this.updateValueState(data, item);
          this.setState({
            editingItem: undefined,
            modalDisabled: false,
          });
        })
        .fail(() => {
          if (this.props.errorMessage) {
            alert(this.props.errorMessage);
          }
          this.setState({
            modalDisabled: false,
          });
        });
    }
  }

  formatData(data) {
    const formattedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formattedData[UtilityHelpers.camelCase(key)] = data[key];
      }
    }
    return formattedData;
  }

  appendAssetToState(data) {
    const values = this.state.values.slice();
    if (this.props.type === 'array_asset') {
      values.push(this.formatData(data));
    } else {
      values[0] = this.formatData(data);
    }
    this.setState({ values });
    this.stopAppending();
    if (this.props.appendCallback) {
      this.props.appendCallback(data);
    }
  }

  removeAssetFromState(data) {
    const id = data.id;
    const values = this.state.values.slice();
    const position = values.findIndex(value => value.id === id);
    values.splice(position, 1);
    this.setState({ values });
    if (this.props.removeCallback) {
      this.props.removeCallback(data);
    }
  }

  assetDelete(status, value) {
    if (status === 'regular') {
      return <ProductAssetDelete id={value.id} handleDelete={this.handleDelete} />;
    }
  }

  assetEdit(status, value) {
    if (this.props.edit && status === 'regular') {
      return <ProductAssetEdit id={value.id} handleEdit={this.handleEdit} />;
    }
  }

  assetMove(status, index, value) {
    if (status === 'regular') {
      return (
        <div>
          <div
            className={`${this.props.className} ${this.props.colCls} ${this.ajaxLoaderMovingCls(
              this.state.moving,
              index,
            )}`}
          >
            <div className="ajax-loader-placeholder">
              <img src={this.props.ajaxLoader} className="ajax-loader" />
            </div>
          </div>
          <ProductAssetMove
            id={value.id}
            index={index}
            total={this.state.values.length - 1}
            handleMove={this.handleMove}
          />
        </div>
      );
    }
  }

  assetMoveLoader(index) {
    return (
      <div
        className={`${this.props.className} ${this.props.colCls} ${this.ajaxLoaderMovingCls(
          this.state.moving,
          index,
        )}`}
      >
        <div className="ajax-loader-placeholder">
          <img src={this.props.ajaxLoader} className="ajax-loader" />
        </div>
      </div>
    );
  }

  assetAppend(status) {
    if (status === 'regular') {
      return (
        <div>
          <div
            className={`${this.props.className} ${this.props.colCls} ${this.ajaxLoaderAppendingCls(
              this.state.appending,
            )}`}
          >
            <div className="ajax-loader-placeholder">
              <img src={this.props.ajaxLoader} className="ajax-loader" />
            </div>
          </div>
          <ProductAssetAppend
            placeholder={this.props.placeholder}
            attributeName={this.props.attributeName}
            handleAppend={this.handleAppend}
            colCls={this.props.colCls}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.state.values.map((value, index) => {
          if (value && value.imageUrls && value.imageUrls.thumb) {
            return (
              <div key={index}>
                <div
                  className={`${this.props.placeholderCls} ${this.props.className} ${
                    this.props.colCls
                  } ${this.assetCls(this.state.moving, index)}`}
                  data-id={`${value.id}`}
                >
                  {this.assetEdit(this.props.renderStatus, value)}
                  {this.assetDelete(this.props.renderStatus, value)}
                  {this.assetMove(this.props.renderStatus, index, value)}
                  <img src={value.imageUrls.thumb} />
                </div>
                {this.assetMoveLoader(index)}
              </div>
            );
          }
        })}
        {this.assetAppend(this.props.renderStatus)}
        <GeneratedFormModal
          title={this.state.modalTitle}
          data={this.state.editingItem}
          fields={this.props.fields}
          disabled={this.state.modalDisabled}
          handleChange={this.handleModalChange}
          handleCancel={this.handleModalCancel}
          handleSubmit={this.handleModalSubmit}
        />
      </div>
    );
  }
}

AssetControl.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  localizable: PropTypes.bool,
  type: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  assetPropertiesName: PropTypes.string,
  assetPath: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholderCls: PropTypes.string,
  placeholder: PropTypes.string,
  ajaxLoader: PropTypes.string,
  colCls: PropTypes.string,
  renderStatus: PropTypes.string,
  thumbs: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  edit: PropTypes.bool,
  appendCallback: PropTypes.func,
  removeCallback: PropTypes.func,
};

AssetControl.defaultProps = {
  initialValue: [],
  className: 'product-asset',
  placeholderCls: 'product-asset-placeholder',
  placeholder,
  ajaxLoader,
  colCls: '',
  renderStatus: 'regular',
  thumbs: [],
  errorMessage: 'There has been an error in the server request',
  fields: [
    {
      name: 'title',
      label: 'Title',
      type: 'text',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
    },
  ],
  appendCallback: undefined,
  removeCallback: undefined,
};
