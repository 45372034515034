import React from 'react';
import BatchesTableContainer from './BatchesTableContainer';

export default function BatchesPage() {
  return (
    <div>
      <h1>Document Import Batches</h1>
      <BatchesTableContainer />
    </div>
  );
}
