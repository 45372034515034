import React from 'react';
import PropTypes from 'prop-types';

function handleChange(e, filterChangeCallback) {
  filterChangeCallback(e.target.value);
}

export default function StepsFilter({ filterValue, filterChangeCallback, filterSummary }) {
  return (
    <div className="row">
      <div className="col-sm-2">Filter</div>
      <div className="col-sm-4">
        <select
          className="form-control"
          value={filterValue}
          onChange={e => {
            handleChange(e, filterChangeCallback);
          }}
        >
          <option value="all">Show all entries [{filterSummary.all}]</option>
          <option value="matched">Show only matched [{filterSummary.matched}]</option>
          <option value="unmatched">Show only unmatched [{filterSummary.unmatched}]</option>
          <option value="discontinued">
            Show only discontinued [{filterSummary.discontinued}]
          </option>
        </select>
      </div>
    </div>
  );
}

StepsFilter.propTypes = {
  filterValue: PropTypes.string.isRequired,
  filterChangeCallback: PropTypes.func.isRequired,
  filterSummary: PropTypes.object,
};
