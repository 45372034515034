import React, { useState, useCallback } from 'react';
import CacheResetButton from './CacheResetButton';

interface ResetState {
  loading: boolean;
  errored: boolean;
  succeeded: boolean;
}

interface CacheResetterProps {
  label: string;
  localeName: string | string[];
}

function createDefaultState(): ResetState {
  return { loading: false, errored: false, succeeded: false };
}

export default function CacheResetter({ label, localeName }: CacheResetterProps): JSX.Element {
  const [resetState, setResetState] = useState<ResetState>(createDefaultState());

  const resetToOriginalState = useCallback((): void => {
    setTimeout(() => {
      setResetState(createDefaultState());
    }, 2000);
  }, [setResetState]);

  const successAndReset = useCallback((): void => {
    setResetState({ ...resetState, succeeded: true });
    resetToOriginalState();
  }, [resetState, setResetState, resetToOriginalState]);

  const errorAndReset = useCallback((): void => {
    setResetState({ ...resetState, errored: true });
    resetToOriginalState();
  }, [resetState, setResetState, resetToOriginalState]);

  async function performCacheReset(): Promise<void> {
    const options: RequestInit = {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        Accepts: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ locale_name: localeName }),
    };

    try {
      const response = await fetch('/caches/reset', options);
      if (!response.ok) {
        errorAndReset();
        return;
      }
    } catch {
      errorAndReset();
      return;
    }
    successAndReset();
  }

  const handleClick = useCallback((): void => {
    setResetState({ ...resetState, loading: true });
    performCacheReset();
  }, []);

  return (
    <CacheResetButton
      label={label}
      loading={resetState.loading}
      errored={resetState.errored}
      succeeded={resetState.succeeded}
      clickHandler={handleClick}
    />
  );
}
