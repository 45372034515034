import React from 'react';
import PropTypes from 'prop-types';

function displayText(label, errored, succeeded) {
  if (errored) {
    return 'Error resetting cache';
  }
  if (succeeded) {
    return 'Success';
  }
  return label;
}

export default function CacheResetButton(props) {
  return (
    <button className="btn btn-primary" disabled={props.loading} onClick={props.clickHandler}>
      {displayText(props.label, props.errored, props.succeeded)}
    </button>
  );
}

CacheResetButton.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errored: PropTypes.bool.isRequired,
  succeeded: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
};
