import React from 'react';
import PropTypes from 'prop-types';
import Editor from '../Editor';
import FormRow from '../FormRow';
import FormLabel from '../FormLabel';
import FormField from '../FormField';
import FormModal from '../../shared/FormModal';
import EditableList from '../../shared/EditableList';
import ColorPicker from '../ColorPicker';
import UtilityHelper from '../../../shure/UtilityHelpers';
import AssetControl from '../formFieldComponents/AssetControl';
import { readAsset } from '../../productdb/AssetService';
import { copyBlockAligmentOptions } from './richContentOptions';

export default class SecondaryCopyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      content: {
        items:
          SecondaryCopyContainer.reformatItems(props.initialValue.items) ||
          SecondaryCopyContainer.defaultProps.initialValue.items,
      },
      modal: {
        active: false,
        title: '',
        submitLabel: '',
        mode: 'add',
        index: undefined,
        item_title: '',
        item_copy: '',
        item_text_color: '',
        item_background_color: '',
        item_image_id: '',
        image: {},
      },
    };
    this.itemDefaultTitle = 'Quality that’s in the Details';
    this.handleImageAddition = this.handleImageAddition.bind(this);
    this.handleImageRemoval = this.handleImageRemoval.bind(this);
  }

  handleModalAttr(event, attrName) {
    this.setState({
      modal: {
        ...this.state.modal,
        [attrName]: event.target.value,
      },
    });
  }

  handleModalEditorChange(event) {
    this.setState({
      modal: {
        ...this.state.modal,
        item_copy: event.target.getContent(),
      },
    });
  }

  handleModalColorPicker(event, attrName) {
    this.setState({
      modal: {
        ...this.state.modal,
        [attrName]: event.color,
      },
    });
  }

  handleNewItem(e) {
    e.preventDefault();
    this.setState({
      modal: {
        ...this.state.modal,
        active: true,
        title: 'New Item',
        submitLabel: 'Create',
        mode: 'add',
        item_title: this.itemDefaultTitle,
        item_copy: '',
        item_text_color: '',
        item_background_color: '',
        item_copy_block_alignment: '',
        item_image_id: '',
        index: undefined,
        image: {},
      },
    });
  }

  handleHideModal() {
    document.body.classList.remove('modal-open');
    this.setState({
      modal: {
        ...this.state.modal,
        active: false,
        title: '',
        submitLabel: '',
        item_title: this.itemDefaultTitle,
        item_copy: '',
        item_text_color: '',
        item_background_color: '',
        item_copy_block_alignment: '',
        item_image_id: '',
        index: undefined,
        image: {},
      },
    });
  }

  handleModalShow() {
    document.body.classList.add('modal-open');
    const imageId = this.state.modal.item_image_id;
    if (!imageId || imageId === '') {
      return;
    }
    readAsset(this.props.productCategory, imageId)
      .then(json => {
        this.setState({
          modal: {
            ...this.state.modal,
            item_image_id: imageId,
            image: json,
          },
        });
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  handleEditItem(index) {
    const item = this.state.content.items[index];
    this.setState({
      modal: {
        ...this.state.modal,
        active: true,
        title: 'Edit Item',
        submitLabel: 'Save',
        mode: 'edit',
        index,
        item_title: item.title,
        item_copy: item.copy,
        item_text_color: item.text_color,
        item_background_color: item.background_color,
        item_copy_block_alignment: item.copy_block_alignment,
        item_image_id: item.image_id,
        image: {},
      },
    });
  }

  handleSaveItem(e) {
    e.preventDefault();
    const { items } = this.state.content;
    const newItem = {
      title: this.state.modal.item_title,
      copy: this.state.modal.item_copy,
      text_color: this.state.modal.item_text_color,
      background_color: this.state.modal.item_background_color,
      copy_block_alignment: this.state.modal.item_copy_block_alignment,
      image_id: this.state.modal.item_image_id,
    };
    if (this.state.modal.mode === 'add') {
      items.push(newItem);
    } else if (this.state.modal.mode === 'edit') {
      items[this.state.modal.index] = newItem;
    }

    this.setState({
      content: {
        ...this.state.content,
        items,
      },
      modal: {
        active: false,
        item_title: this.itemDefaultTitle,
        item_copy: '',
        item_text_color: '',
        item_background_color: '',
        item_copy_block_alignment: '',
        item_image_id: '',
        index: undefined,
        image: {},
      },
    });
  }

  handleMoveItem(prevIndex, newIndex) {
    const { items } = this.state.content;
    const item = items[prevIndex];
    items.splice(prevIndex, 1); // remove from position
    items.splice(newIndex, 0, item); // add at new position
    this.setState({ content: { ...this.state.content, items } });
  }

  handleRemoveItem(index) {
    const { items } = this.state.content;
    items.splice(index, 1);
    this.setState({ content: { ...this.state.content, items } });
  }

  handleImageAddition(data) {
    const imageId = data && data.id ? data.id : '';
    this.setState({ modal: { ...this.state.modal, item_image_id: imageId } });
  }

  handleImageRemoval() {
    this.setState({ modal: { ...this.state.modal, item_image_id: '' } });
  }

  renderForm() {
    return (
      <React.Fragment>
        <FormRow>
          <FormLabel htmlFor="secondary_copy_title">Title</FormLabel>
          <FormField>
            <input
              type="text"
              className="form-control"
              id="secondary_copy_title"
              value={this.state.modal.item_title}
              onChange={event => this.handleModalAttr(event, 'item_title')}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="secondary_copy_copy">Copy</FormLabel>
          <FormField>
            <Editor
              // adding unique key would force reinit/rerender of component with new value.
              key={this.state.modal.index}
              initialValue={this.state.modal.item_copy}
              onChange={event => this.handleModalEditorChange(event)}
              id="secondary_copy_copy"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_image">Image</FormLabel>
          <FormField>
            <AssetControl
              key={this.state.modal.index}
              colCls="col-sm-3"
              fileType="generic_product_image"
              assetPath={this.props.assetPath}
              attributeName="rich_content_secondary_copy_section_item"
              type="asset"
              appendCallback={this.handleImageAddition}
              removeCallback={this.handleImageRemoval}
              initialValue={this.state.modal.image}
              thumbs={[this.state.modal.image && this.state.modal.image.thumb_url]}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="primary_copy_copy_block_alignment">Copy Block Alignment</FormLabel>
          <FormField>
            <select
              className="form-control"
              value={this.state.modal.item_copy_block_alignment}
              onChange={event => this.handleModalAttr(event, 'item_copy_block_alignment')}
            >
              {copyBlockAligmentOptions()}
            </select>
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="secondary_copy_text_color">Text Color</FormLabel>
          <ColorPicker
            key={this.state.modal.index}
            value={this.state.modal.item_text_color}
            onChangeComplete={event => this.handleModalColorPicker(event, 'item_text_color')}
            id="secondary_copy_text_color"
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="secondary_copy_background_color">Background Color</FormLabel>
          <ColorPicker
            key={this.state.modal.index}
            value={this.state.modal.item_background_color}
            onChangeComplete={event => this.handleModalColorPicker(event, 'item_background_color')}
            id="secondary_copy_background_color"
          />
        </FormRow>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <input
          type="hidden"
          name={this.props.fieldName}
          id={this.props.fieldId}
          value={JSON.stringify(this.state.content, null, 2)}
        />
        <EditableList
          title="Secondary Copy"
          editable
          removable
          sortable
          onEdit={index => this.handleEditItem(index)}
          onRemove={index => this.handleRemoveItem(index)}
          onMove={(prevIndex, newIndex) => this.handleMoveItem(prevIndex, newIndex)}
        >
          {this.state.content.items.map((item, index) => (
            <div key={index} className="secondary_copy_item list-item-content col-sm-5">
              <span className="list-title">{item.title}</span>
            </div>
          ))}
        </EditableList>
        <FormModal
          active={this.state.modal.active}
          title={this.state.modal.title}
          submitButtonText={this.state.modal.submitLabel}
          onHide={() => this.handleHideModal()}
          onSubmit={e => this.handleSaveItem(e)}
          onShow={() => this.handleModalShow()}
        >
          {this.renderForm()}
        </FormModal>
        <button
          name="button"
          type="button"
          className="btn btn-default btn-primary"
          onClick={e => this.handleNewItem(e)}
        >
          New Item of Secondary Copy
        </button>
      </React.Fragment>
    );
  }
}

SecondaryCopyContainer.reformatItems = items => {
  if (typeof items === 'undefined') {
    return undefined;
  }
  if (!Array.isArray(items)) {
    return undefined;
  }
  const mapItems = items || [];
  const result = mapItems.map(item => {
    const newItem = {};
    Object.keys(item).map(attr => {
      newItem[UtilityHelper.snakeCase(attr)] = item[attr];
    });
    return newItem;
  });
  return result;
};

SecondaryCopyContainer.defaultProps = {
  initialValue: {
    items: [],
  },
  assetPath: '',
  productCategory: '',
};

SecondaryCopyContainer.propTypes = {
  initialValue: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        copy: PropTypes.string,
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        copyBlockAlignment: PropTypes.string,
        imageId: PropTypes.string,
      }),
    ),
  }),
  assetPath: PropTypes.string,
  productCategory: PropTypes.string,
};
