import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import EditableList from '../../shared/EditableList';
import ArraySelectField from '../formFieldComponents/ArraySelectField';
import FormFieldWrapper from '../formFieldComponents/FormFieldWrapper';
import ReadOnlySelectList from '../formFieldComponents/ReadOnlySelectList';
import FieldControlBase from '../formFieldComponents/FieldControlBase';
import ArraySelectControl from '../ArraySelectControl';

export default class SelectWithWeight extends ArraySelectControl {
  constructor(props) {
    super(props);
    this.state = {
      attributeValue: this.getInitialValue(),
      weights: this.props.initialValueWeights,
    };
    this.weightFieldName = this.props.weightFieldName;
    this.options = this.getSelectOptions();
  }

  handleAppend() {
    const { attributeValue } = this.state;
    const { weights } = this.state;
    if (attributeValue.length === this.options.length - 1) {
      // 1 for please select
      return;
    }
    attributeValue.push('');
    weights[''] = 1;
    this.setState({ attributeValue, weights });
  }

  handleRemove(index) {
    const { attributeValue } = this.state;
    const uuid = attributeValue[index];
    attributeValue.splice(index, 1);
    const { weights } = this.state;
    delete weights[uuid];
    this.setState({ attributeValue, weights });
  }

  handleChange(index, event) {
    const { attributeValue } = this.state;
    const uuid = event.target.value;
    if (attributeValue.includes(uuid)) {
      return;
    }
    const prevUuid = attributeValue[index];
    attributeValue[index] = uuid;
    const { weights } = this.state;
    const prevValue = weights[prevUuid];
    weights[uuid] = prevValue;

    this.setState({ attributeValue, weights });
  }

  handleWeightChange(e, uuid) {
    const { weights } = this.state;
    weights[uuid] = e.target.value;
    this.setState({ weights });
  }

  renderReadOnly() {
    return (
      <FormFieldWrapper>
        <div
          className="select-fields-area"
          data-drag-target={this.props.sortable}
          data-attr={this.props.attributeName}
        >
          <ReadOnlySelectList value={this.state.attributeValue} options={this.props.options} />
        </div>
      </FormFieldWrapper>
    );
  }

  getSelectOptions() {
    const options = this.props.options.map((item, optionsId) => (
      <option key={`${this.props.name}[options][${optionsId}]`} value={item[1]}>
        {item[0]}
      </option>
    ));
    options.unshift(
      <option key="" value="">
        Please select
      </option>,
    );
    return options;
  }

  renderRegular() {
    return (
      <FormFieldWrapper>
        <div
          className="select-with-weight"
          data-drag-target={this.props.sortable}
          data-attr={this.props.attributeName}
        >
          <input
            type="hidden"
            name={this.weightFieldName}
            value={JSON.stringify(this.state.weights)}
          />
          {this.getDefaultInput()}
          <EditableList
            title={this.props.title}
            removable
            sortable={this.props.sortable}
            onRemove={this.handleRemove.bind(this)}
            onMove={this.handleMove.bind(this)}
          >
            {this.state.attributeValue.map((value, index) => (
              <React.Fragment key={value}>
                <ArraySelectField
                  key={value}
                  id={index}
                  name={this.props.fieldName}
                  value={value}
                  options={this.options}
                  sortable={this.props.sortable}
                  handleChange={this.handleChange.bind(this)}
                  selectCls={this.props.selectCls}
                />
                <div className="weight col-sm-2">
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.weights[value]}
                    min="1"
                    onChange={e => this.handleWeightChange(e, value)}
                  />
                </div>
              </React.Fragment>
            ))}
          </EditableList>
          {this.getAppendButton()}
        </div>
      </FormFieldWrapper>
    );
  }

  renderLocalizable() {
    return this.renderRegular();
  }
}

const propTypes = _.clone(FieldControlBase.propTypes);
/* eslint-disable max-len */
propTypes.initialValue = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
);
propTypes.shadowedValue = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
);
/* eslint-enable max-len */
propTypes.options = PropTypes.array;
propTypes.sortable = PropTypes.bool;
propTypes.title = PropTypes.string;
propTypes.selectCls = PropTypes.string;
propTypes.initialValueWeights = PropTypes.any;
SelectWithWeight.propTypes = propTypes;

SelectWithWeight.defaultProps = {
  options: [],
  sortable: false,
  title: 'selectfield',
  initialValueWeights: {},
};
