import React from 'react';
import PropTypes from 'prop-types';

function stateToClassName(state) {
  switch (state) {
    case 'running':
      return 'label-primary';
    case 'paused':
      return 'label-warning';
    case 'completed':
      return 'label-success';
    case 'failed':
      return 'label-danger';
    default:
      return 'label-defult';
  }
}

export default function BatchStatusLabel({ batch }) {
  return <span className={`label ${stateToClassName(batch.state)}`}>{batch.state}</span>;
}

BatchStatusLabel.propTypes = {
  batch: PropTypes.shape().isRequired,
};
