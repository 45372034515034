import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import FieldControlBase from './formFieldComponents/FieldControlBase';
import FormFieldWrapper from './formFieldComponents/FormFieldWrapper';

export default class CheckboxControl extends FieldControlBase {
  checkboxValue(attributeValue) {
    return attributeValue ? '1' : '0';
  }

  handleChange(event) {
    super.handleChange(event, () => event.target.checked);
  }

  renderReadOnly() {
    const className = `fa ${this.state.attributeValue ? 'fa-check-square-o' : 'fa-square-o'}`;
    return (
      <FormFieldWrapper>
        <div>
          <i className={className} />
        </div>
      </FormFieldWrapper>
    );
  }

  renderRegular() {
    return (
      <FormFieldWrapper>
        <div className="inputGroup">
          <input type="hidden" name={this.props.fieldName} value="0" />
          <input
            type="checkbox"
            value={this.checkboxValue(this.state.attributeValue)}
            checked={this.state.attributeValue}
            name={this.props.fieldName}
            id={this.props.fieldId}
            onChange={this.handleChange.bind(this)}
          />
        </div>
      </FormFieldWrapper>
    );
  }

  renderLocalizable() {
    return this.renderRegular();
  }
}

const propTypes = _.clone(FieldControlBase.propTypes);
propTypes.initialValue = PropTypes.bool;
CheckboxControl.propTypes = propTypes;
