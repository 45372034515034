import React from 'react';
import PropTypes from 'prop-types';
import deleteStepDocuments from './StepsClient';

export default class StepActionLink extends React.Component {
  constructor(props) {
    super(props);
    this.deleteButton = this.deleteButton.bind(this);
    this.state = {
      isHidden: !(this.props.step.state === 'completed' && this.props.step.message == null),
      state: this.props.step.state,
    };
  }

  isHidden() {
    return this.state.isHidden ? 'hide' : '';
  }

  handleSuccess(step) {
    this.setState({
      isHidden: true,
      state: step.state,
    });
  }

  deleteButton() {
    deleteStepDocuments(this.props.step.id).then(step => this.handleSuccess(step));
  }

  render() {
    return (
      <button
        type="button"
        className={`btn btn-default ${this.isHidden()}`}
        onClick={this.deleteButton}
      >
        <i className="fa fa-trash-o" />
      </button>
    );
  }
}

StepActionLink.propTypes = {
  step: PropTypes.shape({
    state: PropTypes.string,
  }),
};
