import React from 'react';
import PropTypes from 'prop-types';
import { showBatch, showBatchSteps } from './batchesClient';
import BatchSummary from './BatchSummary';
import StepsTable from './StepsTable';
import StepsFilter from './StepsFilter';
import l from '../../../util/l';

export default class BatchPage extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilter = this.updateFilter.bind(this);
    this.state = {
      filter: 'matched',
    };
  }

  componentDidMount() {
    const batchId = this.props.match.params.id;
    showBatch(batchId).then(batch => {
      this.setState({
        batch,
      });
    });
    showBatchSteps(batchId).then(steps => {
      this.setState({
        steps,
      });
    });
  }

  pageTitle() {
    const title = 'Document Import Batch';
    if (!this.state.batch) {
      return title;
    }
    return `${title} as of ${l(this.state.batch.created_at, 'dateTimeShort')}`;
  }

  updateFilter(filterValue) {
    this.setState({
      filter: filterValue,
    });
  }

  showFilter() {
    if (!this.state.steps) {
      return null;
    }
    return (
      <div className="row navigation-section">
        <div className="col-sm-12">
          <StepsFilter
            filterValue={this.state.filter}
            filterChangeCallback={this.updateFilter}
            filterSummary={this.buildSetpsStats()}
          />
        </div>
      </div>
    );
  }

  buildSetpsStats() {
    const steps = this.state.steps;
    return {
      all: steps.length,
      unmatched: steps.filter(step => step.is_matched === false).length,
      matched: steps.filter(step => step.is_matched === true).length,
      discontinued: steps.filter(step => step.pubs_discontinued === true).length,
    };
  }

  filteredSteps() {
    if (!this.state.steps) {
      return null;
    }

    const f = this.state.filter;
    if (!f || f === 'all') {
      return this.state.steps;
    } else if (f === 'unmatched') {
      return this.state.steps.filter(step => step.is_matched === false);
    } else if (f === 'matched') {
      return this.state.steps.filter(step => step.is_matched === true);
    } else if (f === 'discontinued') {
      return this.state.steps.filter(step => step.pubs_discontinued === true);
    }

    return [];
  }

  render() {
    return (
      <div>
        <h1>{this.pageTitle()}</h1>
        <div className="row navigation-section">
          <div className="col-sm-12">
            <BatchSummary batch={this.state.batch} />
          </div>
        </div>
        {this.showFilter()}
        <div className="row">
          <div className="col-sm-12">
            <StepsTable steps={this.filteredSteps()} />
          </div>
        </div>
      </div>
    );
  }
}

BatchPage.propTypes = {
  match: PropTypes.shape({}),
};
