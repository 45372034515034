import React from 'react';
import { mount } from 'enzyme';
// import { render, fireEvent } from '@testing-library/react';
import VideoSelectControl, { VideoProp } from '../VideoSelectControl';

describe('<VideoSelectControl>', () => {
  function buildSelectElement(): JSX.Element {
    const videos: VideoProp[] = [
      { id: null, path: '' },
      { id: 'someid', path: '/path/to/the/video' },
      { id: 'otherid', path: '/path/to/another/video' },
    ];
    return (
      <VideoSelectControl
        attributeName="featured_video_uuid"
        attributeValue="thevalue"
        label="Video"
        target="product"
        videos={videos}
        locale="zh-CN"
      />
    );
  }

  it('should render a <label> with the given label', () => {
    const wrapper = mount(buildSelectElement());

    expect(
      wrapper
        .find('label')
        .first()
        .text(),
    ).toEqual('Video');
  });

  it('should render the options based on the given videos', () => {
    const wrapper = mount(buildSelectElement());

    expect(wrapper.find('select option')).toHaveLength(3);
  });

  it('should render a hidden field with the correct name attribute', () => {
    const wrapper = mount(buildSelectElement());

    expect(wrapper.find('input[type="hidden"][name="product[featured_video_uuid]"]')).toHaveLength(
      1,
    );
  });

  it('should render a hidden field with the correct name value', () => {
    const wrapper = mount(buildSelectElement());

    expect(wrapper.find('input[type="hidden"][value="thevalue"]')).toHaveLength(1);
  });

  it('should render without a form name for the select attribute', () => {
    const wrapper = mount(buildSelectElement());

    expect(
      wrapper
        .find('select')
        .first()
        .getElement().props.name,
    ).toBe(undefined);
  });

  it('should update the value of the text field when the select field changes', () => {
    const wrapper = mount(buildSelectElement());

    const handler = wrapper
      .find('select')
      .first()
      .invoke('onChange');
    if (handler) {
      handler({ currentTarget: { value: 'newId' } } as React.FormEvent<HTMLSelectElement>);
    }

    expect(
      wrapper
        .find('input')
        .first()
        .props().value,
    ).toBe('newId');
  });
});
