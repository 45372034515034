import React from 'react';
import Spinner from '../../shared/Spinner';
import BatchStatusLabel from './BatchStatusLabel';
import l from '../../../util/l';

export default function BatchSummary({ batch }) {
  if (!batch) {
    return <Spinner />;
  }
  return (
    <div className="information-grid">
      <div className="row">
        <div className="col-sm-2">Status</div>
        <div className="col-sm-4">
          <BatchStatusLabel batch={batch} />
        </div>
        <div className="col-sm-2">Created at</div>
        <div className="col-sm-4">{l(batch.created_at, 'dateTimeShort')}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">Started at</div>
        <div className="col-sm-4">
          {batch.state !== 'paused' ? l(batch.started_at, 'dateTimeShort') : ''}
        </div>
        <div className="col-sm-2">Ended at</div>
        <div className="col-sm-4">{l(batch.ended_at, 'dateTimeShort')}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">Documents compl./total</div>
        <div className="col-sm-4">
          {batch.steps_total - batch.steps_pending}/{batch.steps_total}
        </div>
        <div className="col-sm-2">Message</div>
        <div className="col-sm-4">{batch.message}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">From:</div>
        <div className="col-sm-4">{batch.csv_url}</div>
      </div>
    </div>
  );
}
