import React from 'react';
// import PropTypes from 'prop-types';

import * as LocaleMappingClient from './LocaleMappingClient';
import Spinner from '../../shared/Spinner';

const DEFAULT_LANGUAGE = 'en';

export default class BatchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localeMappings: [],
      localeMappingIds: [],
      locales: [],
      languages: [],
      LocaleAddNewSelectionId: '',
      LanguageAddNewSelection: {},
      loading: false,
    };
    // localeMappings: [locale_id, locale_name, languages]
    // locales: [id, name]
  }

  componentDidMount() {
    this.getLocaleMappings();
    this.getLocales();
  }

  // SERVER

  getLocaleMappings() {
    this.setState({ loading: true });
    LocaleMappingClient.getLocaleMappings().then(data => {
      const localeMappingIds = data.map(loc => loc.locale_id);
      const forAddNewLangSelection = {};
      localeMappingIds.forEach(
        lmId =>
          (forAddNewLangSelection[lmId] =
            this.state.LanguageAddNewSelection[lmId] || DEFAULT_LANGUAGE),
      );
      this.setState({
        localeMappings: data,
        localeMappingIds,
        LanguageAddNewSelection: forAddNewLangSelection,
        loading: false,
      });
    });
  }

  getLocales() {
    this.setState({ loading: true });
    LocaleMappingClient.getLocales().then(data => {
      const locales = [];
      const languages = [];
      Object.keys(data.locales).map(key => {
        const transformed = { id: key, value: data.locales[key] };
        locales.push(transformed);
        languages.push(transformed.value);
      });
      this.setState({
        locales,
        languages,
        loading: false,
        LocaleAddNewSelectionId: locales[0].id,
      });
    });
  }

  // HANDLES

  handleLocaleAddNew() {
    if (this.state.localeMappingIds.includes(this.state.LocaleAddNewSelectionId)) {
      return;
    }
    const defaultLanguages = [DEFAULT_LANGUAGE];
    const data = { locale_id: this.state.LocaleAddNewSelectionId, languages: defaultLanguages };
    LocaleMappingClient.updateLocaleMapping(data).then(() => this.getLocaleMappings());
  }

  handleLocaleDelete(id) {
    LocaleMappingClient.deleteLocaleMapping(id).then(() => this.getLocaleMappings());
  }

  handleLanguageAdd(localeMappingId) {
    let localeMapping = this.state.localeMappings.filter(lm => lm.locale_id === localeMappingId);
    if (localeMapping.length === 0) {
      return;
    }
    localeMapping = localeMapping[0];
    const selectedLanguage = this.state.LanguageAddNewSelection[localeMappingId];
    if (
      localeMapping.locale_name === selectedLanguage ||
      localeMapping.languages.includes(selectedLanguage)
    ) {
      return;
    }
    localeMapping.languages.push(selectedLanguage);
    const data = { locale_id: localeMappingId, languages: localeMapping.languages };
    LocaleMappingClient.updateLocaleMapping(data).then(() => this.getLocaleMappings());
  }

  handleLanguageRemove(localeMappingId, lang) {
    let localeMapping = this.state.localeMappings.filter(lm => lm.locale_id === localeMappingId);
    if (localeMapping.length === 0) return;
    localeMapping = localeMapping[0];
    localeMapping.languages.splice(localeMapping.languages.indexOf(lang), 1);
    const data = { locale_id: localeMappingId, languages: localeMapping.languages };
    LocaleMappingClient.updateLocaleMapping(data).then(() => this.getLocaleMappings());
  }

  handleLanguageSelect(e, localeMappingId) {
    this.setState({
      LanguageAddNewSelection: {
        ...this.state.LanguageAddNewSelection,
        [localeMappingId]: e.target.value,
      },
    });
  }

  // RENDERs

  renderLocaleAddNew(localeId) {
    return (
      <div className="navbar">
        <select
          defaultValue={this.state.locales[0]}
          value={this.state.LocaleAddNewSelectionId}
          onChange={e => this.setState({ LocaleAddNewSelectionId: e.target.value })}
        >
          {this.state.locales.map(locOpt => (
            <option
              key={locOpt.id}
              value={locOpt.id}
              disabled={this.state.localeMappingIds.includes(locOpt.id)}
            >
              {locOpt.value}
            </option>
          ))}
        </select>
        <a className="btn btn-default" onClick={() => this.handleLocaleAddNew(localeId)}>
          Add new mapping
        </a>
        {this.state.loading ? <Spinner /> : ''}
      </div>
    );
  }

  renderLanguageAdd(localeMappingId, localeMappingName, languages) {
    return (
      <React.Fragment>
        <select
          value={this.state.LanguageAddNewSelection[localeMappingId]}
          onChange={e => this.handleLanguageSelect(e, localeMappingId)}
        >
          {this.state.languages.map(lang => (
            <option value={lang} disabled={lang === localeMappingName || languages.includes(lang)}>
              {lang}
            </option>
          ))}
        </select>
        <a
          onClick={() => this.handleLanguageAdd(localeMappingId)}
          className="LocaleMapping__Language__Add btn btn-default"
        >
          <i className="fa fa-plus" />
        </a>
      </React.Fragment>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderLanguagesList(localeMappingId, languages) {
    return (
      <React.Fragment>
        {languages.map(lang =>
          lang === DEFAULT_LANGUAGE ? (
            <span className="LocaleMapping__Languages__Lang__Default btn">{lang}</span>
          ) : (
            <a
              onClick={() => this.handleLanguageRemove(localeMappingId, lang)}
              className="LocaleMapping__Languages__Lang btn btn-default"
            >
              {lang}
              <i className="fa fa-times" />
            </a>
          ),
        )}
      </React.Fragment>
    );
  }

  renderLocaleDelete(localeMappingId) {
    return (
      <a
        onClick={() => this.handleLocaleDelete(localeMappingId)}
        alt="delete"
        className="LocaleMapping__Delete btn btn-default"
      >
        <i className="fa fa-times" />
      </a>
    );
  }
  render() {
    return (
      <div className="LocaleMapping">
        <h1>Locale Mapping</h1>
        {this.renderLocaleAddNew()}
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-striped LocaleMapping__Table">
              <thead>
                <tr>
                  <th>Website Language</th>
                  <th>Document Languages</th>
                  <th>Add</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.state.localeMappings.map(item => (
                  <tr key={item.locale_id}>
                    <td>{item.locale_name}</td>
                    <td>{this.renderLanguagesList(item.locale_id, item.languages)}</td>
                    <td>
                      {this.renderLanguageAdd(item.locale_id, item.locale_name, item.languages)}
                    </td>
                    <td>{this.renderLocaleDelete(item.locale_id)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
