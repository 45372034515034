import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { produce } from 'immer';
import { createValueSet } from '../../../apiClients/valueSets';
import history from '../../../util/history';

export default function ValueSetsNewPage(): JSX.Element {
  interface NewValueSet {
    name?: string;
    key?: string;
  }

  const [valueSet, setValueSet] = useState<NewValueSet>({ name: '', key: '' });

  function handleFormChange(e: React.FormEvent<HTMLInputElement>): void {
    const t = e.currentTarget;
    const nextSet = produce(valueSet, draft => {
      draft[t.name] = t.value;
    });
    setValueSet(nextSet);
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    await createValueSet(valueSet);
    history.push('/products/value_sets');
  }

  return (
    <>
      <h1>New Value Set</h1>
      <form onSubmit={handleSubmit}>
        <div role="tabpanel">
          <ul className="nav nav-tabs" role="tablist">
            <li className="active" role="presentation">
              <a aria-controls="basics" data-toggle="tab" href="#basics" role="tab">
                Basics
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="basics" role="tabpanel">
              <div className="form-horizontal">
                <div className="form-group row">
                  <label className="col-xs-12 col-sm-2" htmlFor="name">
                    Name
                  </label>
                  <div className="col-xs-12 col-sm-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={valueSet.name}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xs-12 col-sm-2" htmlFor="relation_key">
                    Key
                  </label>
                  <div className="col-xs-12 col-sm-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="key"
                        value={valueSet.key}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group form-actions row">
                  <div className="col-xs-10 col-xs-offset-2">
                    <input
                      type="submit"
                      name="commit"
                      value="Save"
                      className="btn btn-default btn-primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Link className="btn btn-warning" to="../value_sets">
        Cancel
      </Link>
    </>
  );
}
