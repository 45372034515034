import React from 'react';
import PropTypes from 'prop-types';

export default class EditableList extends React.Component {
  onMove(index, up) {
    const prevIndex = index;
    const newIndex = up
      ? Math.max(index - 1, 0)
      : Math.min(index + 1, this.props.children.length - 1);
    if (prevIndex !== newIndex) {
      this.props.onMove(prevIndex, newIndex);
    }
  }

  getRemoveControl(child, index) {
    if (!this.props.removable) {
      return null;
    }
    return (
      <div className="label label-default" onClick={this.props.onRemove.bind(this, index)}>
        <i className="fa fa-trash-o" />
      </div>
    );
  }

  getSortControl(child, index) {
    if (!this.props.sortable) {
      return null;
    }
    return (
      <div className="attribute-drag">
        <div className="attribute-drag-up label" onClick={this.onMove.bind(this, index, true)}>
          <i className="fa fa-chevron-up" />
        </div>
        <div className="attribute-drag-down label" onClick={this.onMove.bind(this, index, false)}>
          <i className="fa fa-chevron-down" />
        </div>
      </div>
    );
  }

  getEditControl(child, index) {
    if (!this.props.editable) {
      return null;
    }
    return (
      <div className="label control-editable" onClick={this.props.onEdit.bind(this, index)}>
        <i className="fa fa-pencil" />
      </div>
    );
  }

  render() {
    return (
      <ul id={this.props.title} className="editable-list">
        {React.Children.map(this.props.children, (child, i) => (
          <li className="list-item clearfix">
            {child}
            {this.getEditControl(child, i)}
            {this.getRemoveControl(child, i)}
            {this.getSortControl(child, i)}
          </li>
        ))}
      </ul>
    );
  }
}

EditableList.propTypes = {
  title: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  removable: PropTypes.bool,
  sortable: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onMove: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

EditableList.defaultProps = {
  title: '',
  editable: false,
  removable: false,
  sortable: false,
  onMove() {},
  onRemove() {},
  onEdit() {},
};
