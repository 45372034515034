export interface Locale {
  id: string;
  name: string;
  base: boolean;
  default: boolean;
}

const url = '/products/locales.json';

async function fetchLocales(): Promise<Locale[]> {
  const response = await fetch(url);
  if (response.status !== 200) {
    throw new Error(`Error fetching locales: ${response.statusText}`);
  }
  const json = await response.json();
  const locales = json.map(loc => {
    const locale: Locale = {
      id: loc.id,
      name: loc.name,
      base: loc.base,
      default: loc.default,
    };
    return locale;
  });
  return locales;
}

// eslint-disable-next-line import/prefer-default-export
export { fetchLocales };
