import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchValueSets, deleteValueSet, ValueSet } from '../../../apiClients/valueSets';

export default function ValueSetsPage(): JSX.Element {
  const [valueSets, setValueSets] = useState<ValueSet[]>([]);

  async function callAPIs(): Promise<void> {
    const vs = await fetchValueSets();
    setValueSets(vs);
  }

  useEffect(() => {
    callAPIs();
  }, []);

  async function handleDelete(id: string): Promise<void> {
    if (!confirm('Are you sure?')) {
      return;
    }
    await deleteValueSet(id);
    callAPIs();
  }

  return (
    <>
      <h1>Value Sets</h1>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Key</th>
            <th className="col-xs-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {valueSets.map(vs => (
            <tr key={vs.id}>
              <td>
                <Link to={`/products/value_sets/${vs.id}/values`}>{vs.name}</Link>
              </td>
              <td>{vs.key}</td>
              <td>
                <a className="btn btn-danger" onClick={() => handleDelete(vs.id)}>
                  <i className="fa fa-trash-o" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link to="value_sets/new" className="btn btn-default">
        New Value Set
      </Link>
    </>
  );
}
