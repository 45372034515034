import React, { useState, useEffect } from 'react';
import { produce } from 'immer';
import { match as mtch } from 'react-router-dom';
import {
  fetchValueSet,
  fetchValueSetValues,
  updateValueSetValues,
  ValueSet,
  ValueSetValue,
} from '../../../apiClients/valueSets';
import { fetchLocales, Locale } from '../../../apiClients/locales';

interface ValueSetEditPageProps {
  match: mtch;
}

interface TranslationSet {
  key: string;
  valueSetValues: ValueSetValue[];
  error?: Error;
}

export default function ValueSetsEditPage({ match }: ValueSetEditPageProps): JSX.Element | null {
  const [valueSet, setValueSet] = useState<ValueSet>();
  const [languages, setLanguages] = useState<Locale[]>();
  const [newVsvs, setNewVsvs] = useState<ValueSetValue[]>();
  const [translationSets, setTranslationSets] = useState<TranslationSet[]>();

  function buildNewValueSetValues(key: string, vs: ValueSet, langs: Locale[]): ValueSetValue[] {
    return langs.map(lang => ({
      valueSetId: vs.id,
      localeId: lang.id,
      key,
    }));
  }

  function languagesFromLocales(locales: Locale[]): Locale[] {
    return locales.filter(loc => loc.name.indexOf('-') === -1);
  }

  function getValueSetId(): string {
    // eslint-disable-next-line dot-notation
    return match.params['id'];
  }

  function buildTranslationSets(vsvs: ValueSetValue[]): TranslationSet[] {
    const trSets: TranslationSet[] = [];
    if (!vsvs) {
      return trSets;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const vsv of vsvs) {
      let translationSet = trSets.find(ts => ts.key === vsv.key);
      if (!translationSet) {
        translationSet = { key: vsv.key, valueSetValues: [] } as TranslationSet;
        trSets.push(translationSet);
      }
      translationSet.valueSetValues.push(vsv);
    }
    return trSets;
  }

  async function callAPIs(): Promise<void> {
    const vs = fetchValueSet(getValueSetId());
    const locales = fetchLocales();
    const valueSetV = fetchValueSetValues(getValueSetId());
    Promise.all([vs, locales, valueSetV]).then(resolved => {
      setValueSet(resolved[0]);
      const langs = languagesFromLocales(resolved[1]);
      setLanguages(langs);
      setNewVsvs(buildNewValueSetValues('', resolved[0], langs));
      setTranslationSets(buildTranslationSets(resolved[2]));
    });
  }

  useEffect(() => {
    callAPIs();
  }, []);

  if (!languages || !valueSet) {
    return null;
  }

  function handleUpdateNewKey(e: React.FormEvent<HTMLInputElement>, id?: string): void {
    const useVsvs = id ? null : newVsvs;
    if (!useVsvs) {
      return;
    }

    const key = e.currentTarget.value;
    const updatedVsvs = produce(useVsvs, draftVsvs => {
      // eslint-disable-next-line no-restricted-syntax
      for (const vsv of draftVsvs) {
        vsv.key = key;
      }
    });
    if (!id) {
      setNewVsvs(updatedVsvs);
    }
  }

  function handleUpdateValue(
    e: React.FormEvent<HTMLInputElement>,
    localeId: string,
    id?: string,
  ): void {
    const useVsvs = id ? null : newVsvs;
    if (!useVsvs) {
      return;
    }
    const keyValue = e.currentTarget.value;
    const updatedVsvs = produce(useVsvs, draftVsvs => {
      const vsv = draftVsvs.find(v => v.localeId === localeId);
      if (vsv) {
        vsv.value = keyValue;
      }
    });
    setNewVsvs(updatedVsvs);
  }

  async function handleFormSubmit(e: React.FormEvent, vsvs: ValueSetValue[]): Promise<void> {
    e.preventDefault();
    try {
      await updateValueSetValues(getValueSetId(), vsvs);
      setTranslationSets(buildTranslationSets(await fetchValueSetValues(getValueSetId())));
      if (valueSet && languages) {
        setNewVsvs(buildNewValueSetValues('', valueSet, languages));
      }
      alert('Created new translation set');
    } catch (error) {
      alert(`Error creating translation set: ${error.message}`);
    }
  }

  function languageNameFromId(id: string): string {
    if (!languages) {
      return '';
    }
    const lang = languages.find(l => l.id === id);
    if (!lang) {
      return '';
    }
    return lang.name.toUpperCase();
  }

  function renderValueSetValuePanel(vsvs: ValueSetValue[]): JSX.Element | null {
    if (!languages || !valueSet || !vsvs || vsvs.length < 1) {
      return null;
    }

    return (
      <form onSubmit={e => handleFormSubmit(e, vsvs)}>
        <div className="tab-pane active" role="tabpanel">
          <div className="row">
            <div className="col-sm-4">
              <input
                type="text"
                value={vsvs[0].key}
                onChange={e => handleUpdateNewKey(e, vsvs[0].id)}
              />
            </div>
            <div className="col-sm-8">
              <div className="form-horizontal">
                {vsvs.map(vs => (
                  <div className="form-group" key={[vs.id, vs.localeId, vs.key].join('-')}>
                    <label className="col-sm-2">{languageNameFromId(vs.localeId)}</label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        value={vs.value || ''}
                        onChange={e => handleUpdateValue(e, vs.localeId, vs.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <input
            type="submit"
            className="btn btn-default"
            value="Create Translation Set"
            disabled={!vsvs[0].key || vsvs[0].key === ''}
          />
        </div>
      </form>
    );
  }

  function handleUpdateTranslationSetKey(
    e: React.FormEvent<HTMLInputElement>,
    activeTs: TranslationSet,
  ): void {
    if (!translationSets) {
      return;
    }

    const key = e.currentTarget.value;
    const newTranslationSets = produce(translationSets, draftTranslationSets => {
      // eslint-disable-next-line no-restricted-syntax
      for (const translationSet of draftTranslationSets) {
        // eslint-disable-next-line no-restricted-syntax
        for (const vsv of translationSet.valueSetValues) {
          // eslint-disable-next-line no-restricted-syntax
          for (const activeVsv of activeTs.valueSetValues) {
            if (vsv.id && vsv.id === activeVsv.id) {
              vsv.key = key;
            }
          }
        }
      }
    });
    setTranslationSets(newTranslationSets);
  }

  function handleUpdateValueSetValue(e: React.FormEvent<HTMLInputElement>, id?: string): void {
    if (!translationSets) {
      return;
    }

    const vsvValue = e.currentTarget.value;
    const updatedVsvs = produce(translationSets, draftTranslationSets => {
      // eslint-disable-next-line no-restricted-syntax
      for (const translationSet of draftTranslationSets) {
        const vsv = translationSet.valueSetValues.find(value => value.id === id);
        if (vsv) {
          vsv.value = vsvValue;
        }
      }
    });
    setTranslationSets(updatedVsvs);
  }

  async function handleTranslationSetFormSubmit(
    e: React.FormEvent,
    translationSet: TranslationSet,
  ): Promise<void> {
    e.preventDefault();
    try {
      await updateValueSetValues(getValueSetId(), translationSet.valueSetValues);
      setTranslationSets(buildTranslationSets(await fetchValueSetValues(getValueSetId())));
      alert('Successfully updated translation set');
    } catch (error) {
      if (!translationSets) {
        return;
      }
      const newTranslationSets = produce(translationSets, draftTranslationSets => {
        const ts = draftTranslationSets.find(dts => dts.key === translationSet.key);
        if (ts) {
          ts.error = error;
        }
      });
      setTranslationSets(newTranslationSets);
    }
  }

  function renderTranslationSetPanels(): JSX.Element[] | null {
    if (!languages || !valueSet || !translationSets || translationSets.length < 1) {
      return null;
    }

    return translationSets.map(translationSet => (
      <div key={translationSet.key}>
        <form onSubmit={e => handleTranslationSetFormSubmit(e, translationSet)}>
          <div className="tab-pane active" role="tabpanel">
            <div className="row">
              <div className="col-sm-4">
                <input
                  type="text"
                  value={translationSet.valueSetValues[0].key}
                  onChange={e => handleUpdateTranslationSetKey(e, translationSet)}
                />
              </div>
              <div className="col-sm-8">
                <div className="form-horizontal">
                  {translationSet.valueSetValues.map(vsv => (
                    <div className="form-group" key={[vsv.id, vsv.localeId, vsv.key].join('-')}>
                      <label className="col-sm-2">{languageNameFromId(vsv.localeId)}</label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          value={vsv.value || ''}
                          onChange={e => handleUpdateValueSetValue(e, vsv.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {translationSet.error ? (
              <div className="error">{translationSet.error.message}</div>
            ) : null}
            <input
              type="submit"
              className="btn btn-default"
              value={`Update Translation Set “${translationSet.valueSetValues[0].key}”`}
              disabled={
                !translationSet.valueSetValues[0].key || translationSet.valueSetValues[0].key === ''
              }
            />
          </div>
        </form>
      </div>
    ));
  }

  if (!newVsvs) {
    return null;
  }

  return (
    <>
      <h1>Edit Value Set {valueSet.name}</h1>
      {renderValueSetValuePanel(newVsvs)}
      {renderTranslationSetPanels()}
    </>
  );
}
