import React, { ReactNode } from 'react';

interface FormRowProps {
  label: string;
  attributeName: string;
  children: ReactNode;
}

const FormRow = ({ label, attributeName, children }: FormRowProps): JSX.Element => {
  return (
    <div className="form-group row">
      <label className="col-xs-12 col-sm-2" htmlFor={attributeName}>
        {label}
      </label>
      <div className="col-xs-12 col-sm-8">{children}</div>
    </div>
  );
};

export default FormRow;
