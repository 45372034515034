import TextAreaControl from './TextAreaControl';

export default class WysiwygControl extends TextAreaControl {
  constructor(props) {
    super(props);
    this.editor = null;
  }

  formClassName() {
    let className = super.formClassName() || '';
    className += ' wysiwyg';
    return className;
  }

  handleChange(event) {
    if (this.editor) {
      super.handleChange(event, () => event.editor.getData());
    }
  }

  resetToShadowedValue() {
    super.resetToShadowedValue();
    if (this.editor) {
      this.editor.setData(this.props.shadowedValue);
    }
  }

  handleKeepEmpty(event) {
    const keepEmpty = event.target.checked;
    if (this.editor) {
      if (keepEmpty) {
        this.editor.setData('');
      } else {
        this.editor.setData(this.state.keepEmptySavedValue);
      }
      this.editor.setReadOnly(keepEmpty);
    }
    return super.handleKeepEmpty(event);
  }

  componentDidMount() {
    this.editor = CKEDITOR.replace(this.props.fieldName);
    this.editor.on('change', this.handleChange.bind(this));
  }

  render() {
    return super.render();
  }
}
