import React from 'react';
import PropTypes from 'prop-types';
import Portal from './Portal';

export default class FormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: 'none',
      in: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.active && nextProps.active) {
      this.show();
    } else if (this.props.active && !nextProps.active) {
      this.hide();
    }
  }

  onTransitionEnd(callback) {
    const uniq = parseInt(Math.random() * 1000000000, 10).toString(36);
    jQuery(this.modalNode).on(
      `transitionend.${uniq} webkitTransitionEnd.${uniq}
      oTransitionEnd.${uniq}`,
      function onTransitionEnd() {
        jQuery(this).off(`.${uniq}`);
        callback.apply(this, arguments);
      },
    );
  }

  onNewFrame(callback) {
    const rAF = window.requestAnimationFrame;
    rAF(() => rAF(callback));
  }

  handleClick(e) {
    if (e.target.classList.contains('modal-close')) {
      this.hide();
    }
  }

  show() {
    if (this.state.display === 'none') {
      this.props.onShow();
      this.setState({ display: 'block' });
      this.onNewFrame(() => {
        this.setState({ in: true });
        this.onTransitionEnd(() => this.props.onShown());
      });
    }
  }

  hide() {
    if (this.state.in) {
      this.setState({ in: false });
      this.props.onHide();
      this.onTransitionEnd(() => {
        this.setState({ display: 'none' });
        this.props.onHidden();
      });
    }
  }

  render() {
    const inCls = this.state.in ? 'in' : '';
    return (
      <Portal>
        <div
          className={`modal fade ${inCls} modal-close ${this.props.className}`}
          id={this.props.modalId}
          tabIndex="-1"
          role="dialog"
          style={{ display: this.state.display }}
          ref={ref => (this.modalNode = ref)}
          onClick={this.handleClick.bind(this)}
        >
          <div className="modal-dialog">
            <form
              action={this.props.action}
              onSubmit={this.props.onSubmit}
              className="modal-content"
            >
              <div className="modal-header">
                <button type="button" className="close modal-close" aria-label="Close">
                  <span className="modal-close" aria-hidden="true">
                    &times;
                  </span>
                </button>
                <h4 className="modal-title">{this.props.title}</h4>
              </div>
              <div className="modal-body">{this.props.children}</div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-default">
                  {this.props.submitButtonText}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div style={{ display: this.state.display }} className={`modal-backdrop fade ${inCls}`} />
      </Portal>
    );
  }
}

FormModal.propTypes = {
  modalId: PropTypes.string,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  action: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  onShow: PropTypes.func,
  onShown: PropTypes.func,
  onHide: PropTypes.func,
  onHidden: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

FormModal.defaultProps = {
  title: '',
  active: false,
  className: '',
  submitButtonText: 'Submit',
  onShow() {},
  onShown() {},
  onHide() {},
  onHidden() {},
};
