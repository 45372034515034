import React from 'react';
import FieldControlBase from './formFieldComponents/FieldControlBase';
import FormFieldCopyElement from './formFieldComponents/FormFieldCopyElement';
import FormFieldKeepEmptyElement from './formFieldComponents/FormFieldKeepEmptyElement';
import FormFieldWrapper from './formFieldComponents/FormFieldWrapper';

export default class TextAreaControl extends FieldControlBase {
  formClassName() {
    return 'form-control';
  }

  renderReadOnly() {
    return (
      <FormFieldWrapper>
        <div>{this.state.attributeValue}</div>
      </FormFieldWrapper>
    );
  }

  renderRegular() {
    return (
      <FormFieldWrapper>
        <textarea
          type="textarea"
          className={this.formClassName()}
          value={this.state.attributeValue}
          name={this.props.fieldName}
          id={this.props.fieldId}
          onChange={this.handleChange.bind(this)}
        />
      </FormFieldWrapper>
    );
  }

  renderLocalizable() {
    return (
      <div>
        <FormFieldWrapper>
          <textarea
            type="textarea"
            className={this.formClassName()}
            value={this.state.attributeValue}
            disabled={this.state.keepEmptyValue}
            placeholder={this.shadowedValue()}
            name={this.props.fieldName}
            id={this.props.fieldId}
            onChange={this.handleChange.bind(this)}
          />
        </FormFieldWrapper>
        <div className="attribute">
          <FormFieldCopyElement
            currentValue={this.state.attributeValue}
            shadowedValue={this.shadowedValue()}
            reset={this.resetToShadowedValue.bind(this)}
          />
          <FormFieldKeepEmptyElement
            keepEmptyFieldName={this.props.keepEmptyFieldName}
            keepEmptyFieldId={this.props.keepEmptyFieldId}
            keepEmptyFieldValue={this.state.keepEmptyValue}
            handleChange={this.handleKeepEmpty.bind(this)}
          />
        </div>
      </div>
    );
  }
}
