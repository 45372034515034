const options = {
  mode: 'cors',
  cache: 'default',
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
  },
};

const path = '/users';

export function showUser(userId) {
  return fetch(`${path}/${encodeURIComponent(userId)}`, options)
    .then(response => response.json())
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
}

export function createAuthorToken(userId) {
  const opts = { ...options, ...{ method: 'POST' } };
  return fetch(`${path}/${encodeURIComponent(userId)}/author_token`, opts)
    .then(response => response.json())
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
}

export function deleteAuthorToken(userId) {
  const opts = { ...options, ...{ method: 'DELETE' } };
  return fetch(`${path}/${encodeURIComponent(userId)}/author_token`, opts)
    .then(response => response.json())
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
}
