import React from 'react';
import PropTypes from 'prop-types';
import SketchPicker from 'react-color';

export default class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideColorPicker: true,
      colorValue: this.props.value,
    };
  }

  handleOnChangeComplete(color, event) {
    const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    const sendEvent = { ...event, color: rgbaColor };
    this.props.onChangeComplete(sendEvent);
    this.setState({
      ...this.state,
      colorValue: rgbaColor,
    });
  }

  handleTextField(event) {
    this.setState({ ...this.state, colorValue: event.target.value });
  }

  handlePreviewClick(event) {
    event.preventDefault();
    this.hideColorPicker(!this.state.hideColorPicker);
  }

  hideColorPicker(value) {
    this.setState({ ...this.state, hideColorPicker: value });
  }

  render() {
    return (
      <div className="colorpicker">
        <div className="col-sm-2">
          <input
            type="text"
            className="form-control"
            value={this.state.colorValue}
            onChange={event => this.handleTextField(event)}
            onFocus={() => this.hideColorPicker(false)}
            onBlur={() => this.hideColorPicker(true)}
            id={this.props.id}
          />
        </div>
        <div className="col-sm-1">
          <button
            className="preview"
            style={{ backgroundColor: this.state.colorValue }}
            onClick={e => this.handlePreviewClick(e)}
            onBlur={() => this.hideColorPicker(true)}
          />
          <SketchPicker
            className={'picker ' + (this.state.hideColorPicker ? 'hidden' : '')}
            color={this.state.colorValue}
            onChangeComplete={(color, event) => this.handleOnChangeComplete(color, event)}
          />
        </div>
      </div>
    );
  }
}

ColorPicker.defaultProps = {
  onChangeComplete: () => null,
  value: '',
  id: 'colorpicker',
};

ColorPicker.propTypes = {
  onChangeComplete: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
};
