import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BatchStatusLabel from './BatchStatusLabel';
import BatchActionLink from './BatchActionLink';
import Spinner from '../../shared/Spinner';

import l from '../../../util/l';

function batchLink(batch) {
  if (!batch) {
    return null;
  }
  return `/products/document_imports/batches/${batch.id}`;
}

export default function BatchesTableComponent({ batches, updateBatchDisplay }) {
  if (!batches) {
    return <Spinner />;
  }
  return (
    <table className="table table-striped table-hover table-condensed">
      <thead>
        <tr>
          <th>Status</th>
          <th>Created at</th>
          <th>Started at</th>
          <th>Completed at</th>
          <th title="Pending">P</th>
          <th title="Running">R</th>
          <th title="Paused">P</th>
          <th title="Failed">F</th>
          <th title="Completed">C</th>
          <th title="Total">T</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {batches.map(batch => (
          <tr key={batch.id}>
            <td>
              <BatchStatusLabel batch={batch} />
            </td>
            <td>
              <Link to={batchLink(batch)}>{l(batch.created_at, 'dateTimeShort')}</Link>
            </td>
            <td>
              <Link to={batchLink(batch)}>
                {batch.state === 'paused' ? '' : l(batch.started_at, 'dateTimeShort')}
              </Link>
            </td>
            <td>
              <Link to={batchLink(batch)}>{l(batch.ended_at, 'dateTimeShort')}</Link>
            </td>
            <td>{batch.steps_pending}</td>
            <td>{batch.steps_running}</td>
            <td>{batch.steps_paused}</td>
            <td>{batch.steps_failed}</td>
            <td>{batch.steps_completed}</td>
            <td>{batch.steps_total}</td>
            <td>
              <BatchActionLink batch={batch} updateBatchDisplay={updateBatchDisplay} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

BatchesTableComponent.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.shape),
  updateBatchDisplay: PropTypes.func.isRequired,
};

BatchesTableComponent.defaultProps = {
  batches: null,
};
