import React from 'react';
import PropTypes from 'prop-types';
import FormModal from './FormModal';
import AssetControl from '../forms/formFieldComponents/AssetControl';
import SelectControl from '../forms/SelectControl';

/*
 * This component generates a form from the specified fields props
 * and displays it in a FormModal.
 *
 * Usage example:
 * <FormModal
 *   fields={[
 *     {
 *       name: "title",
 *       label: "Title",
 *       type: "text",
 *       defaultValue: "",
 *     },
 *     {
 *       name: "description",
 *       label: "Description",
 *       type: "textarea",
 *     },
 *     {
 *       name: "url",
 *       label: "URL",
 *       type: "text",
 *       placeholder: "http://www.example.com",
 *     },
 *   ]}
 * />
 */
export default class GeneratedFormModal extends React.Component {
  getFieldId(name) {
    return `${this.props.id}-${name}`;
  }

  getInputElement(attr) {
    const { data, assetProperties, disabled, fields, id } = this.props;
    if (data) {
      const value = data[attr.name] || attr.defaultValue || '';
      const props = Object.assign({}, attr, {
        id: this.getFieldId(attr.name),
        className: 'form-control',
        disabled,
        value,
        onChange: this.handleChange.bind(this, attr.name),
      });
      switch (attr.type) {
        case 'textarea':
          return <textarea {...props} />;
        case 'asset':
          return <AssetControl {...assetProperties} />;
        case 'select':
          return (
            <div className="row">
              <SelectControl
                {...props}
                options={fields.find(field => field.name === attr.name).options}
                fieldName={attr.name}
                fieldId={id}
                attributeName={attr.name}
                value={data[attr.name]}
                localizable
                baseLocale
              />
            </div>
          );
        default:
          return <input {...props} />;
      }
    }
  }

  handleChange(name, e) {
    this.props.handleChange(name, e.target.value);
  }

  render() {
    return (
      <FormModal
        className={`form-modal ${this.props.className} form-horizontal`}
        active={!!this.props.data}
        title={this.props.title}
        onSubmit={this.props.handleSubmit}
        onHidden={this.props.handleCancel}
        submitButtonText={this.props.submitButtonText}
      >
        {this.props.fields.map(attr => (
          <div className="form-group" key={attr.name}>
            <label className="control-label col-sm-2" htmlFor={this.getFieldId(attr.name)}>
              {attr.label}
            </label>
            <div className="col-sm-10">{this.getInputElement(attr)}</div>
          </div>
        ))}
      </FormModal>
    );
  }
}

GeneratedFormModal.defaultProps = {
  id: 'form-modal',
  title: '',
  data: undefined,
  handleChange() {},
  handleSubmit() {},
  handleCancel() {},
  disabled: false,
  submitButtonText: 'Save',
  fields: [],
};

GeneratedFormModal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  disabled: PropTypes.bool,
  submitButtonText: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  assetProperties: PropTypes.object,
};
