import React from 'react';
import PropTypes from 'prop-types';

export default function FormLabel({ htmlFor, children }) {
  return (
    <label className="col-xs-12 col-sm-2" id="skdfj" htmlFor={htmlFor}>
      {children}
    </label>
  );
}

FormLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};
