import React from 'react';
import UtilityHelpers from '../../../shure/UtilityHelpers';

const ReadOnlyExternalContentList = ({ value, isReview }) => (
  <ul>
    {value.map((item, index) => (
      <li key={index} className="list-item">
        <div className="list-item-content col-xs-12">
          <div className="row">
            <span className="list-title col-sm-4">{item.title}</span>
            {isReview ? (
              <div className="col-sm-2">
                <span className={`list-type ${item.contentType}`}>
                  {UtilityHelpers.capitalize(item.contentType || 'Press')}
                </span>
              </div>
            ) : null}
            <span className={`list-url col-sm-${isReview ? '3' : '5'}`}>{item.url}</span>
          </div>
        </div>
      </li>
    ))}
  </ul>
);

export default ReadOnlyExternalContentList;
