import React, { useState } from 'react';
import { commitChangeset, uploadSpecsFile, ExcelImport } from '../../../apiClients/specs';
import SpecsChangeset from './SpecsChangeset';

export default function SpecsFileUploader(): JSX.Element {
  const [excelImport, setExcelImport] = useState<ExcelImport | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [uploading, setUploading] = useState<boolean>(false);

  function renderError(): JSX.Element {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="alert alert-danger">{error}</div>
        </div>
      </div>
    );
  }

  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    setError(undefined);
    setExcelImport(undefined);
    const t = e.target;
    const file = t.files && t.files[0];
    if (!file) {
      return Promise.resolve();
    }
    try {
      const excel = await uploadSpecsFile(file);
      setExcelImport(excel);
    } catch (err) {
      setError(err.message);
    }
    return Promise.resolve();
  }

  async function commitUpload(): Promise<void> {
    setError(undefined);
    if (!excelImport) {
      return;
    }
    if (!confirm('Are you sure? This cannot be undone.')) {
      return;
    }
    try {
      setUploading(true);
      await commitChangeset(excelImport);
      setExcelImport(undefined);
    } catch (err) {
      setError(err.message);
    }
    setUploading(false);
  }

  function uploadButton(): JSX.Element | null {
    if (!excelImport || excelImport.changesets.length < 1) {
      return null;
    }
    if (uploading) {
      return <span>Uploading...</span>;
    }
    return (
      <button className="btn btn-default" type="button" onClick={commitUpload}>
        Upload Changeset
      </button>
    );
  }

  return (
    <div className="col-sm-12">
      <div className="information-grid">
        {error ? renderError() : null}
        <div className="row">
          <div className="col-sm-1">Upload File</div>
          <div className="col-sm-5">
            <input
              className="btn btn-import"
              accept=".xlsx"
              type="file"
              onChange={handleFileUpload}
            />
          </div>
          <div className="col-sm-6">{uploadButton()}</div>
        </div>
      </div>
      {excelImport ? <SpecsChangeset excelImport={excelImport} /> : null}
    </div>
  );
}
