import React from 'react';
import PropTypes from 'prop-types';

export default class ExcelExportOption extends React.Component {
  getDownloadPath() {
    return `${this.props.downloadPath}?translation=${encodeURIComponent(this.props.locale)}`;
  }

  render() {
    return (
      <li>
        <a href={this.getDownloadPath()}>{this.props.locale.toUpperCase()}</a>
      </li>
    );
  }
}

ExcelExportOption.propTypes = {
  locale: PropTypes.string.isRequired,
  downloadPath: PropTypes.string.isRequired,
};
