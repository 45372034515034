export default class FileUploader {
  constructor(url) {
    this.url = url;
  }

  uploadFile(file, fileType, callback) {
    const formData = new FormData();
    const url = this.url;
    const requestType = 'POST';
    file.filename = file.name;
    formData.append('file_type', fileType);
    formData.append('image', file);

    jQuery
      .ajax({
        type: requestType,
        url,
        data: formData,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
      })
      .done(data => {
        callback(data);
      })
      .fail((xhr, status, text) => {
        alert('Error: ' + text);
      });
  }

  deleteFile(callback) {
    jQuery
      .ajax({
        type: 'DELETE',
        url: this.url,
        error(xhr, status, text) {
          alert('Error : ' + text);
        },
      })
      .done(data => {
        callback(data);
      })
      .fail((xhr, status, text) => {
        alert('Error: ' + text);
      });
  }
}
