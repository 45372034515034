const host =
  process.env.NODE_ENV === 'production'
    ? 'https://partnershop.prod.shureweb.eu'
    : 'http://localhost:3003';

export interface ValueSet {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  key: string;
}

interface NewValueSet {
  name?: string;
  key?: string;
}

export interface ValueSetValue {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  valueSetId: string;
  localeId: string;
  key: string;
  value?: string;
}

async function throwError(response: Response): Promise<void> {
  let error = new Error(response.statusText);
  try {
    const json = await response.json();
    if (json && json.errors && json.errors.length > 0) {
      error = new Error(json.errors[0].detail);
    }
  } catch {
    // nothing
  }
  throw error;
}

function jsonToValueSet(json): ValueSet {
  return {
    id: json.id,
    createdAt: json.created_at,
    updatedAt: json.updated_at,
    name: json.name,
    key: json.key,
  };
}

async function createValueSet(newValueSet: NewValueSet): Promise<void> {
  const response = await fetch(`${host}/value_sets`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newValueSet),
  });
  if (!response.ok) {
    await throwError(response);
  }
}

async function fetchValueSets(): Promise<ValueSet[]> {
  const response = await fetch(`${host}/value_sets`, {
    headers: {
      Accept: 'application/json',
    },
  });
  if (!response.ok) {
    await throwError(response);
  }
  const json = await response.json();
  return json.map(vs => jsonToValueSet(vs));
}

async function fetchValueSet(id: string): Promise<ValueSet> {
  const response = await fetch(`${host}/value_sets/${id}`, {
    headers: {
      Accept: 'application/json',
    },
  });
  if (!response.ok) {
    await throwError(response);
  }
  const json = await response.json();
  return jsonToValueSet(json);
}

async function deleteValueSet(id: string): Promise<void> {
  const response = await fetch(`${host}/value_sets/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
  });
  if (!response.ok) {
    await throwError(response);
  }
}

async function fetchValueSetValues(vsId: string): Promise<ValueSetValue[]> {
  const response = await fetch(`${host}/value_sets/${vsId}/values`, {
    headers: {
      Accept: 'application/json',
    },
  });
  if (!response.ok) {
    await throwError(response);
  }
  const json = await response.json();
  return json.map(
    j =>
      ({
        id: j.id,
        createdAt: j.created_at,
        updatedAt: j.updated_at,
        valueSetId: j.value_set_id,
        localeId: j.locale_id,
        key: j.key,
        value: j.value,
      } as ValueSetValue),
  );
}

async function updateValueSetValues(vsId: string, vsvs: ValueSetValue[]): Promise<void> {
  if (vsvs.length < 1) {
    throw new Error('No values present');
  }
  const updateJSON = vsvs.map(vsv => ({
    id: vsv.id,
    // eslint-disable-next-line @typescript-eslint/camelcase
    value_set_id: vsv.valueSetId,
    // eslint-disable-next-line @typescript-eslint/camelcase
    locale_id: vsv.localeId,
    key: vsv.key,
    value: vsv.value || '',
  }));
  const response = await fetch(`${host}/value_sets/${vsId}/values`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updateJSON),
  });
  if (!response.ok) {
    await throwError(response);
  }
}

export {
  fetchValueSets,
  fetchValueSet,
  createValueSet,
  deleteValueSet,
  fetchValueSetValues,
  updateValueSetValues,
};
