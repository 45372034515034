function dateTimeShort(date) {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).format(date);
}

export default function l(dateTimeString, lFormat) {
  if (!dateTimeString) {
    return null;
  }

  try {
    const dateTime = Date.parse(dateTimeString);
    switch (lFormat) {
      case 'dateTimeShort':
        return dateTimeShort(dateTime);
      default:
        return 'undefined format';
    }
  } catch (e) {
    console.error('Error parsing datetime value', dateTimeString);
    return dateTimeString;
  }
}
