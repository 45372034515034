import React, { useState, FormEvent, useEffect } from 'react';
import { Category } from '../../../apiClients/categories';
import { Locale } from '../../../apiClients/locales';
import { downloadSpecsFile } from '../../../apiClients/specs';

interface SpecsDownloadSelectorProps {
  categories: Category[];
  locales: Locale[];
}

export default function({ categories, locales }: SpecsDownloadSelectorProps): JSX.Element {
  const [category, setCategory] = useState<string | undefined>();
  const [locale, setLocale] = useState<string | undefined>();

  function linkActive(): boolean {
    return !!category && !!locale;
  }

  async function handleDownloadClick(): Promise<void> {
    if (category && locale) {
      await downloadSpecsFile(category, locale);
    }
  }

  function handleCategory(event: FormEvent<HTMLSelectElement>): void {
    setCategory(event.currentTarget.value);
  }

  function handleLocale(event: FormEvent<HTMLSelectElement>): void {
    setLocale(event.currentTarget.value);
  }

  useEffect(() => {
    if (!category && categories.length > 0) {
      setCategory(categories[0].key);
    }
    if (!locale && locales.length > 0) {
      setLocale(locales[0].name);
    }
  });

  return (
    <div className="col-sm-12">
      <div className="information-grid">
        <div className="row">
          <div className="col-sm-1">Category</div>
          <div className="col-sm-4">
            <select value={category} className="form-control" onChange={handleCategory}>
              {categories.map(cat => (
                <option key={cat.key} value={cat.key}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-1">Locale</div>
          <div className="col-sm-4">
            <select value={locale} className="form-control" onChange={handleLocale}>
              {locales.map(loc => (
                <option key={loc.name} value={loc.name}>
                  {loc.name === 'eu' ? 'Default' : loc.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              disabled={!linkActive()}
              type="button"
              onClick={handleDownloadClick}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
