const baseURL = 'https://videos-api.shureweb.eu/product_videos/videos';
const videoBaseURL = 'https://www.shure.com/videofiles/default';
const defaultLanguage = 'en';

export interface Video {
  id: string;
  path: string;
  url?: string;
  youTubeID?: string;
  contentType?: string;
}

async function fetchVideoForLanguage(language: string, id: string): Promise<Video | null> {
  const videoURL = `${baseURL}/${language}`;
  const response = await fetch(videoURL, {
    mode: 'cors',
  });
  if (response.status !== 200) {
    throw new Error(`Error fetching videos: ${response.statusText}`);
  }
  const json = await response.json();
  const vid = json.find(v => {
    return v.jcr_identifier === id;
  });
  if (!vid) {
    return null;
  }
  const video: Video = {
    id: vid.jcr_identifier,
    path: vid.path,
    url: vid.path && vid.path !== '' ? videoBaseURL + vid.path : undefined,
    youTubeID: vid.youtube_id || undefined,
    contentType: vid.content_type || undefined,
  };
  return video;
}

export function localeToVideoLanguage(locale: string): string[] {
  const prefixes = [defaultLanguage];

  const unadjustedLang = locale.indexOf('-') === -1 ? locale : locale.split('-')[0];
  const language = unadjustedLang === 'eu' ? defaultLanguage : unadjustedLang;

  if (language !== defaultLanguage) {
    prefixes.unshift(language);
  }
  if (locale === 'en-ASIA') {
    prefixes.unshift('en-ASIA');
  }

  return prefixes;
}

export async function fetchVideo(locale: string, id: string): Promise<Video | null> {
  const languages = localeToVideoLanguage(locale);

  // eslint-disable-next-line no-restricted-syntax
  for (const language of languages) {
    // eslint-disable-next-line no-await-in-loop
    const video = await fetchVideoForLanguage(language, id);
    if (video) {
      return video;
    }
  }

  return null;
}
