import React from 'react';
import PropTypes from 'prop-types';
import FormRow from '../FormRow';
import FormLabel from '../FormLabel';
import FormField from '../FormField';
import FormModal from '../../shared/FormModal';
import EditableList from '../../shared/EditableList';
import ColorPicker from '../ColorPicker';
import UtilityHelper from '../../../shure/UtilityHelpers';
import AssetControl from '../formFieldComponents/AssetControl';
import { readAsset } from '../../productdb/AssetService';
import { copyBlockAligmentOptions, textAlignmentOptions } from './richContentOptions';

export default class FeatureSectionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      content: {
        title: props.initialValue.title,
        blurbs:
          FeatureSectionContainer.reformatBlurbs(this.props.initialValue.blurbs) ||
          FeatureSectionContainer.defaultProps.initialValue.blurbs,
        image_id: props.initialValue.imageId,
        copy_block_alignment: props.initialValue.copyBlockAlignment,
        text_color: props.initialValue.textColor,
        background_color: props.initialValue.backgroundColor,
      },
      modal: {
        active: false,
        title: '',
        submitLabel: '',
        blurb_title: '',
        blurb_copy: '',
        blurb_copy_block_alignment: '',
        blurb_text_alignment: '',
        blurb_text_color: '',
        blurb_background_color: '',
        blurb_image_id: '',
        blurb_image: {},
      },
    };
    this.handleImageAddition = this.handleImageAddition.bind(this);
    this.handleImageRemoval = this.handleImageRemoval.bind(this);
  }

  componentDidMount() {
    const { imageId } = this.props.initialValue;
    if (!imageId || imageId === '') {
      return;
    }
    readAsset(this.props.productCategory, imageId)
      .then(json => {
        this.setState({ image: json });
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  handleAttr(event, attrName) {
    this.setState({ content: { ...this.state.content, [attrName]: event.target.value } });
  }

  handleColorPicker(event, attrName) {
    this.setState({ content: { ...this.state.content, [attrName]: event.color } });
  }

  handleAppend(e) {
    e.preventDefault();
    const blurbs = this.state.content.blurbs;
    blurbs.push({
      title: '',
      copy: '',
      copy_block_alignment: '',
      text_alignment: '',
      blurb_text_color: '',
      blurb_background_color: '',
      blurb_image_id: '',
    });
    this.setState({
      content: { ...this.state.content, blurbs },
    });
  }

  handleNewBlurb() {
    this.setState({
      modal: {
        ...this.state.modal,
        active: true,
        title: 'New',
        submitLabel: 'Create',
        mode: 'add',
        index: undefined,
        blurb_title: 'New Blurb',
        blurb_copy: '',
        blurb_copy_block_alignment: '',
        blurb_text_alignment: '',
        blurb_text_color: '',
        blurb_background_color: '',
        blurb_image_id: '',
        blurb_image: {},
      },
    });
  }

  handleEditBlurb(index) {
    const blurb = this.state.content.blurbs[index];
    this.setState({
      modal: {
        ...this.state.modal,
        active: true,
        title: 'Edit',
        submitLabel: 'Save',
        mode: 'edit',
        index,
        blurb_title: blurb.title,
        blurb_copy: blurb.copy,
        blurb_copy_block_alignment: blurb.copy_block_alignment,
        blurb_text_alignment: blurb.text_alignment,
        blurb_text_color: blurb.text_color,
        blurb_background_color: blurb.background_color,
        blurb_image_id: blurb.image_id,
        blurb_image: {},
      },
    });
  }

  handleSaveBlurb(event) {
    event.preventDefault();
    const { blurbs } = this.state.content;
    const newBlurb = {
      title: this.state.modal.blurb_title,
      copy: this.state.modal.blurb_copy,
      copy_block_alignment: this.state.modal.blurb_copy_block_alignment,
      text_alignment: this.state.modal.blurb_text_alignment,
      text_color: this.state.modal.blurb_text_color,
      background_color: this.state.modal.blurb_background_color,
      image_id: this.state.modal.blurb_image_id,
    };
    if (this.state.modal.mode === 'add') {
      blurbs.push(newBlurb);
    } else {
      blurbs[this.state.modal.index] = newBlurb;
    }
    this.setState({
      content: {
        ...this.state.content,
        blurbs,
      },
      modal: {
        ...this.state.modal,
        active: false,
        blurb_title: '',
        blurb_copy: '',
        blurb_copy_block_alignment: '',
        blurb_text_alignment: '',
        blurb_text_color: '',
        blurb_background_color: '',
        blurb_image_id: '',
        blurb_image: {},
      },
    });
  }

  handleMoveBlurb(prevIndex, newIndex) {
    const { blurbs } = this.state.content;
    const blurb = blurbs[prevIndex];
    blurbs.splice(prevIndex, 1); // remove from position
    blurbs.splice(newIndex, 0, blurb); // add at new position
    this.setState({ content: { ...this.state.content, blurbs } });
  }

  handleRemoveBlurb(index) {
    const { blurbs } = this.state.content;
    blurbs.splice(index, 1);
    this.setState({ content: { ...this.state.content, blurbs } });
  }

  handleBlurbAttr(attrName, event) {
    this.setState({
      modal: { ...this.state.modal, [attrName]: event.target.value },
    });
  }

  handleBlurbAttrColor(attrName, event) {
    this.setState({
      modal: { ...this.state.modal, [attrName]: event.color },
    });
  }

  handleModalShow() {
    document.body.classList.add('modal-open');
    const imageId = this.state.modal.blurb_image_id;
    if (!imageId || imageId === '') {
      return;
    }
    readAsset(this.props.productCategory, imageId)
      .then(json => {
        this.setState({
          modal: {
            ...this.state.modal,
            blurb_image: json,
          },
        });
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  handleModalHide() {
    document.body.classList.remove('modal-open');
    this.setState({ modal: { ...this.state.modal, active: false } });
  }

  handleImageAddition(data) {
    const imageId = data && data.id ? data.id : '';
    this.setState({ content: { ...this.state.content, image_id: imageId } });
  }

  handleImageRemoval() {
    this.setState({ content: { ...this.state.content, image_id: '' } });
  }

  handleBlurbImageAddition(data) {
    const imageId = data && data.id ? data.id : '';
    this.setState({ modal: { ...this.state.modal, blurb_image_id: imageId } });
  }

  handleBlurbImageRemoval() {
    this.setState({ modal: { ...this.state.modal, blurb_image_id: '' } });
  }

  renderEditableList() {
    return (
      <EditableList
        title="Blurbs"
        sortable
        removable
        editable
        onEdit={index => this.handleEditBlurb(index)}
        onMove={(prevIndex, newIndex) => this.handleMoveBlurb(prevIndex, newIndex)}
        onRemove={index => this.handleRemoveBlurb(index)}
      >
        {this.state.content.blurbs.map((blurb, index) => (
          <div key={index} className="list-item-content col-sm-5">
            <span onClick={() => this.handleEditBlurb(index)} className="list-title">
              {blurb.title}
            </span>
          </div>
        ))}
      </EditableList>
    );
  }

  renderBlurbForm() {
    return (
      <div className="form-horizontal">
        <FormRow>
          <FormLabel htmlFor="feature_section_blurb_title">Title</FormLabel>
          <FormField>
            <input
              type="text"
              id="feature_section_blurb_title"
              value={this.state.modal.blurb_title}
              className="form-control"
              onChange={e => this.handleBlurbAttr('blurb_title', e)}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_blurb_copy">Copy</FormLabel>
          <FormField>
            <textarea
              className="form-control"
              id="feature_section_blurb_copy"
              value={this.state.modal.blurb_copy}
              onChange={e => this.handleBlurbAttr('blurb_copy', e)}
              height={100}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_blurb_image">Image</FormLabel>
          <FormField>
            <AssetControl
              key={this.state.modal.index}
              colCls="col-sm-3"
              fileType="generic_product_image"
              assetPath={this.props.assetPath}
              attributeName="rich_content_feature_section_blurb_image"
              type="asset"
              appendCallback={e => this.handleBlurbImageAddition(e)}
              removeCallback={e => this.handleBlurbImageRemoval(e)}
              initialValue={this.state.modal.blurb_image}
              thumbs={[this.state.modal.blurb_image && this.state.modal.blurb_image.thumb_url]}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_blurb_copy_block_alignment">
            Copy Block Alignment
          </FormLabel>
          <FormField>
            <select
              className="form-control"
              id="feature_section_blurb_copy_block_alignment"
              value={this.state.modal.blurb_copy_block_alignment}
              onChange={e => this.handleBlurbAttr('blurb_copy_block_alignment', e)}
            >
              {copyBlockAligmentOptions()}
            </select>
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_blurb_text_alignment">Text Alignment</FormLabel>
          <FormField>
            <select
              className="form-control"
              id="feature_section_blurb_text_alignment"
              value={this.state.modal.blurb_text_alignment}
              onChange={e => this.handleBlurbAttr('blurb_text_alignment', e)}
            >
              {textAlignmentOptions()}
            </select>
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_blurb_text_color">Text Color</FormLabel>
          <ColorPicker
            key={this.state.modal.index}
            value={this.state.modal.blurb_text_color}
            onChangeComplete={e => this.handleBlurbAttrColor('blurb_text_color', e)}
            id="feature_section_blurb_text_color"
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_blurb_background_color">Background Color</FormLabel>
          <ColorPicker
            key={this.state.modal.index}
            value={this.state.modal.blurb_background_color}
            onChangeComplete={e => this.handleBlurbAttrColor('blurb_background_color', e)}
            id="feature_section_blurb_background_color"
          />
        </FormRow>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <input
          type="hidden"
          name={this.props.fieldName}
          id={this.props.fieldId}
          value={JSON.stringify(this.state.content, null, 2)}
        />
        <FormRow>
          <FormLabel htmlFor="feature_section_title">Title</FormLabel>
          <FormField>
            <input
              type="text"
              value={this.state.content.title}
              className="form-control"
              onChange={e => this.handleAttr(e, 'title')}
              id="feature_section_title"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_blurbs">Feature Blurbs</FormLabel>
          <FormField>
            {this.renderEditableList()}
            <FormModal
              title={`${this.state.modal.title} Blurb`}
              submitButtonText={`${this.state.modal.submitLabel} Blurb`}
              modalId="feature_section_blurb_modal"
              active={this.state.modal.active}
              onSubmit={e => this.handleSaveBlurb(e)}
              onHide={() => this.handleModalHide()}
              onShow={() => this.handleModalShow()}
            >
              {this.renderBlurbForm()}
            </FormModal>
            <button
              name="button"
              type="button"
              className="btn btn-default btn-primary"
              onClick={() => this.handleNewBlurb()}
            >
              New Blurb
            </button>
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_image">Image</FormLabel>
          <FormField>
            <AssetControl
              colCls="col-sm-3"
              fileType="generic_product_image"
              assetPath={this.props.assetPath}
              attributeName="rich_content_feature_section"
              type="asset"
              appendCallback={this.handleImageAddition}
              removeCallback={this.handleImageRemoval}
              initialValue={this.state.image}
              thumbs={[this.state.image && this.state.image.thumb_url]}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_copy_block_alignment">Copy Block Alignment</FormLabel>
          <FormField>
            <select
              className="form-control"
              id="feature_section_copy_block_alignment"
              value={this.state.content.copy_block_alignment}
              onChange={e => this.handleAttr(e, 'copy_block_alignment')}
            >
              {copyBlockAligmentOptions()}
            </select>
          </FormField>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_text_color">Text Color</FormLabel>
          <ColorPicker
            value={this.state.content.text_color}
            onChangeComplete={event => this.handleColorPicker(event, 'text_color')}
            id="feature_section_text_color"
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="feature_section_background_color">Background Color</FormLabel>
          <ColorPicker
            value={this.state.content.background_color}
            onChangeComplete={event => this.handleColorPicker(event, 'background_color')}
            id="feature_section_background_color"
          />
        </FormRow>
      </React.Fragment>
    );
  }
}

FeatureSectionContainer.reformatBlurbs = blurbs => {
  if (typeof blurbs === 'undefined') {
    return undefined;
  }
  if (!Array.isArray(blurbs)) {
    return undefined;
  }
  const mapBlurbs = blurbs || [];
  const result = mapBlurbs.map(blurb => {
    const newBlurb = {};
    Object.keys(blurb).map(attr => {
      newBlurb[UtilityHelper.snakeCase(attr)] = blurb[attr];
    });
    return newBlurb;
  });
  return result;
};

// it is not a deep merge, so if e.g. initialvalue is in anyway set, this
// will be ignored, and you need to use it directly in constructor.
FeatureSectionContainer.defaultProps = {
  initialValue: {
    title: '',
    blurbs: [],
    textColor: '', // null?
    backgroundColor: '',
    imageId: '',
  },
  assetPath: '',
  productCategory: '',
};

const blurbsPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  copy_block_alignment: PropTypes.string,
  text_alignment: PropTypes.string,
});

FeatureSectionContainer.propTypes = {
  initialValue: PropTypes.shape({
    title: PropTypes.string,
    blurbs: PropTypes.arrayOf(blurbsPropType),
    imageId: PropTypes.string,
    copyBlockAlignment: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  assetPath: PropTypes.string,
  productCategory: PropTypes.string,
};
