import React, { useState, useEffect } from 'react';
import SpecsDownloadSelector from './SpecsDownloadSelector';
import SpecsFileUploader from './SpecsFileUploader';
import { Category, fetchCategories } from '../../../apiClients/categories';
import { Locale, fetchLocales } from '../../../apiClients/locales';

export default function(): JSX.Element {
  const [categories, setCategories] = useState<Category[]>([]);
  const [locales, setLocales] = useState<Locale[]>([]);

  async function callAPIs(): Promise<void> {
    setCategories(await fetchCategories());
    setLocales(await fetchLocales());
  }

  useEffect(() => {
    callAPIs();
  }, []);

  return (
    <>
      <h1>Specs</h1>
      <div className="row navigation-section">
        <SpecsDownloadSelector categories={categories} locales={locales} />
      </div>
      <div className="row navigation-section">
        <SpecsFileUploader />
      </div>
    </>
  );
}
