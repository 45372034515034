import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import FieldControlBase from './formFieldComponents/FieldControlBase';
import FormFieldCopyElement from './formFieldComponents/FormFieldCopyElement';
import FormFieldKeepEmptyElement from './formFieldComponents/FormFieldKeepEmptyElement';
import FormFieldWrapper from './formFieldComponents/FormFieldWrapper';

export default class TextFieldControl extends FieldControlBase {
  unitLabel() {
    let unitLabelDiv;
    if (this.props.unitLabel && this.props.unitLabel !== '') {
      unitLabelDiv = <div className="input-group-addon">{this.props.unitLabel}</div>;
    }
    return unitLabelDiv;
  }

  valueWithLabel() {
    let value = this.state.attributeValue;
    if (value && value !== '' && this.props.unitLabel && this.props.unitLabel !== '') {
      value = `${value} ${this.props.unitLabel}`;
    }
    return value;
  }

  renderReadOnly() {
    return (
      <FormFieldWrapper>
        <div className="read-only">{this.valueWithLabel()}</div>
      </FormFieldWrapper>
    );
  }

  renderRegular() {
    return (
      <FormFieldWrapper>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={this.state.attributeValue}
            name={this.props.fieldName}
            id={this.props.fieldId}
            onChange={this.handleChange.bind(this)}
          />
          {this.unitLabel()}
        </div>
      </FormFieldWrapper>
    );
  }

  renderLocalizable() {
    return (
      <div>
        <FormFieldWrapper>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={this.state.attributeValue}
              disabled={this.state.keepEmptyValue}
              placeholder={this.shadowedValue()}
              name={this.props.fieldName}
              id={this.props.fieldId}
              onChange={this.handleChange.bind(this)}
            />
            {this.unitLabel()}
          </div>
        </FormFieldWrapper>
        <div className="attribute">
          <FormFieldCopyElement
            currentValue={this.state.attributeValue}
            shadowedValue={this.shadowedValue()}
            reset={this.resetToShadowedValue.bind(this)}
          />
          <FormFieldKeepEmptyElement
            keepEmptyFieldName={this.props.keepEmptyFieldName}
            keepEmptyFieldId={this.props.keepEmptyFieldId}
            keepEmptyFieldValue={this.state.keepEmptyValue}
            handleChange={this.handleKeepEmpty.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const propTypes = _.clone(FieldControlBase.propTypes);
propTypes.unitLabel = PropTypes.string;
TextFieldControl.propTypes = propTypes;
