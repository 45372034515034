import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../../../util/history';
import BatchesPage from './BatchesPage';
import BatchPage from './BatchPage';
import LocaleMapping from './LocaleMapping';
import '../../shared/navigation.sass';

export default function BatchesRouter() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/products/document_imports/batches" component={BatchesPage} />
        <Route exact path="/products/document_imports/batches/:id" component={BatchPage} />
        <Route
          exact
          path="/products/document_imports/manage_locale_mappings"
          component={LocaleMapping}
        />
      </Switch>
    </Router>
  );
}
