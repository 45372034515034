import React from 'react';
import PropTypes from 'prop-types';

export default function FormRow({ children }) {
  return <div className="form-group row">{children}</div>;
}

FormRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};
