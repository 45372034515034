import React from 'react';
import PropTypes from 'prop-types';
import ExcelExportOption from './ExcelExportOption';

export default class ExcelExportPicker extends React.Component {
  render() {
    return (
      <div className="row">
        <div>
          <div className="btn-group excel-export">
            <button
              type="button"
              className="btn btn-xs btn-default dropdown-toggle"
              data-toggle="dropdown"
            >
              {this.props.name}
              <span className="caret" />
            </button>
            <ul className="dropdown-menu">
              {this.props.locales.map((locale, index) => (
                <ExcelExportOption
                  key={index}
                  locale={locale}
                  downloadPath={this.props.downloadPath}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

ExcelExportPicker.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  name: PropTypes.string,
  downloadPath: PropTypes.string.isRequired,
};
