import React from 'react';
import PropTypes from 'prop-types';

export default class FieldControlBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeValue: props.initialValue === null ? '' : props.initialValue,
      keepEmptyValue: props.keepEmptyFieldInitialValue,
      keepEmptySavedValue: props.initialValue,
    };
  }

  shadowedValue() {
    let shadowed = null;
    if (!this.state.keepEmptyValue) {
      shadowed = this.props.shadowedValue;
    }
    return shadowed;
  }

  resetToShadowedValue() {
    this.setState({ attributeValue: this.props.shadowedValue });
  }

  handleKeepEmpty(event) {
    this.setState({ keepEmptyValue: event.target.checked });
  }

  handleChange(event, func) {
    if (func) {
      this.setState({ attributeValue: func.call(this, event) });
    } else {
      this.setState({ attributeValue: event.target.value });
    }
  }

  render() {
    let field;
    if (this.props.localizable) {
      if (this.props.baseLocale) {
        field = this.renderRegular();
      } else {
        field = this.renderLocalizable();
      }
    } else if (this.props.baseLocale) {
      field = this.renderRegular();
    } else {
      field = this.renderReadOnly();
    }
    return field;
  }
}

FieldControlBase.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  shadowedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  localizable: PropTypes.bool.isRequired,
  baseLocale: PropTypes.bool.isRequired,
  keepEmptyFieldName: PropTypes.string,
  keepEmptyFieldId: PropTypes.string,
  keepEmptyFieldInitialValue: PropTypes.oneOfType([PropTypes.bool]),
};
