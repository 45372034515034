import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import FieldControlBase from './formFieldComponents/FieldControlBase';
import FormFieldWrapper from './formFieldComponents/FormFieldWrapper';
import FormFieldKeepEmptyElement from './formFieldComponents/FormFieldKeepEmptyElement';
import ReadOnlyExternalContentList from './formFieldComponents/ReadOnlyExternalContentList';
import EditableList from '../shared/EditableList';
import GeneratedFormModal from '../shared/GeneratedFormModal';
import UtilityHelpers from '../../shure/UtilityHelpers';

export default class ExternalContentControl extends FieldControlBase {
  constructor(props) {
    super(props);
    this.state = {
      attributeValue: props.initialValue,
      keepEmptyValue: props.keepEmptyFieldInitialValue,
      items: props.items,
      editingItem: undefined,
      modalTitle: '',
      modalDisabled: false,
    };
  }

  formatData(data, caze) {
    const formattedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formattedData[
          caze === 'camel' ? UtilityHelpers.camelCase(key) : UtilityHelpers.snakeCase(key)
        ] = data[key];
      }
    }
    return formattedData;
  }

  refetchList() {
    jQuery.get(this.props.url).done(data => {
      const formattedData = data.map(item => this.formatData(item, 'camel'));
      this.setState({ items: formattedData });
    });
  }

  deleteItem(item) {
    jQuery
      .ajax({
        method: 'DELETE',
        url: `${this.props.url}/${item.id}`,
      })
      .fail(() => {
        if (this.props.errorMessage) {
          alert(this.props.errorMessage);
        }
      })
      .always(this.refetchList.bind(this));
  }

  isEmptyItem(item) {
    return !item || (!item.title && !item.description && !item.url && !item.imageUrls);
  }

  assetProperties(editingItem) {
    const externalContentId = editingItem ? editingItem.id : '';
    const assetPath = this.props.assetProperties.assetPath.split('product_translation')[0];
    // eslint-disable-next-line max-len
    const item = this.state.items.filter(
      externalContent => externalContent.id === externalContentId,
    )[0];
    const thumb = (item && item.imageUrls && item.imageUrls.thumb) || '';
    const imageId = (item && item.imageId && item.imageId) || '';
    return {
      ...this.props.assetProperties,
      colCls: 'col-sm-6',
      attributeName: 'external_content_image',
      assetPath: `${assetPath}external_content/${externalContentId}`,
      thumbs: [thumb],
      initialValue: { ...item, id: imageId },
    };
  }

  getID(item) {
    jQuery
      .ajax({
        method: 'POST',
        url: this.props.url,
        data: { external_content: item },
        dataType: 'json',
      })
      .done(newItem => {
        newItem.index = -1;
        this.setState({
          editingType: 'new',
          editingItem: newItem,
          modalTitle: `New ${this.props.contentName}`,
        });
      })
      .fail(() => {
        if (this.props.errorMessage) {
          alert(this.props.errorMessage);
        }
        this.setState({
          modalDisabled: false,
        });
      })
      .always(this.refetchList.bind(this));
  }

  getFields() {
    return this.props.fields.filter(field => this.isReview() || field.name !== 'contentType');
  }

  handleEdit(index) {
    const item = this.state.items[index];
    this.setState({
      editingType: 'edit',
      editingItem: _.extend({ index }, item),
      modalTitle: `Edit ${this.props.contentName}`,
    });
  }

  handleRemove(index) {
    const item = this.state.items[index];
    if (confirm(this.props.confirmRemoveMessage)) {
      this.deleteItem(item);
    }
  }

  handleMove(index, newIndex) {
    const items = this.state.items.slice();
    const [item] = items.splice(index, 1);
    items.splice(newIndex, 0, item);
    this.setState({ items });
    jQuery
      .ajax({
        method: 'PATCH',
        url: `${this.props.url}/sort`,
        data: {
          ids: items.map(element => element.id),
        },
      })
      .fail(() => {
        if (this.props.errorMessage) {
          alert(this.props.errorMessage);
        }
      })
      .always(this.refetchList.bind(this));
  }

  handleCreate() {
    jQuery
      .get(`${this.props.url}/new`)
      .done(newItem => {
        this.getID(newItem);
      })
      .fail(() => {
        if (this.props.errorMessage) {
          alert(this.props.errorMessage);
        }
      });
  }

  handleModalCancel() {
    const isAbort = !!this.state.editingItem;
    if (isAbort && this.state.editingType === 'new') {
      // Aborting / not saving newly created item
      this.deleteItem(this.state.editingItem);
    }
    this.setState({ editingItem: undefined, editingType: undefined });
  }

  handleModalChange(attrName, value) {
    const editingItem = _.extend({}, this.state.editingItem);
    editingItem[attrName] = value;
    this.setState({ editingItem });
  }

  handleModalSubmit(e) {
    e.preventDefault();
    const item = this.state.editingItem;
    const formattedData = this.formatData(item, 'snake');
    if (this.isEmptyItem(item)) {
      return alert(this.props.cantSaveEmptyMessage);
    }
    this.setState({ modalDisabled: true });
    jQuery
      .ajax({
        method: 'PATCH',
        url: `${this.props.url}/${item.id}`,
        data: { external_content: formattedData },
        dataType: 'json',
      })
      .done(() => {
        this.setState({
          editingItem: undefined,
          modalDisabled: false,
        });
      })
      .fail(() => {
        if (this.props.errorMessage) {
          alert(this.props.errorMessage);
        }
        this.setState({
          modalDisabled: false,
        });
      })
      .always(this.refetchList.bind(this));
  }

  handleKeepEmpty(event) {
    const keepEmptyValue = event.target.checked;
    this.setState({ keepEmptyValue });
    jQuery
      .ajax({
        method: 'PATCH',
        url: `${this.props.url}/handle_empty_field`,
        data: {
          nil_override: {
            [this.props.attributeName.replace(/_id/, '')]: keepEmptyValue ? 'on' : 'off',
          },
        },
        dataType: 'json',
      })
      .fail(() => {
        this.setState({ keepEmptyValue: !keepEmptyValue });
      });
  }

  isReview() {
    return this.props.contentName === 'Review';
  }

  newContentButton() {
    const { contentName } = this.props;
    return (
      <button
        name="button"
        type="button"
        className="btn btn-default btn-primary"
        onClick={this.handleCreate.bind(this)}
      >
        New {contentName}
      </button>
    );
  }

  editableList() {
    const { title } = this.props;
    const { items } = this.state;
    return (
      <EditableList
        sortable
        removable
        editable
        title={title}
        onMove={this.handleMove.bind(this)}
        onRemove={this.handleRemove.bind(this)}
        onEdit={this.handleEdit.bind(this)}
      >
        {items.map((item, i) => (
          <div
            key={i}
            className={`list-item-content col-sm-8 ${this.isReview() ? 'col-md-9 col-lg-10' : ''}`}
          >
            <div className="row">
              <span
                className="list-title col-sm-4"
                title={item.title}
                onClick={this.handleEdit.bind(this, i)}
              >
                {item.title}
              </span>
              {this.isReview() ? (
                <div className="col-sm-2">
                  <span className={`list-type ${item.contentType}`}>
                    {UtilityHelpers.capitalize(item.contentType || 'Press')}
                  </span>
                </div>
              ) : null}
              <a href={item.url} className={`list-url col-sm-${this.isReview() ? '5' : '7'}`}>
                {item.url}
              </a>
            </div>
          </div>
        ))}
      </EditableList>
    );
  }

  renderRegular() {
    const { modalTitle, editingItem, modalDisabled } = this.state;
    return (
      <FormFieldWrapper type="external_content">
        <div className="external-content-control">
          <div className="row">{this.editableList()}</div>
          {this.newContentButton()}
          <GeneratedFormModal
            title={modalTitle}
            data={editingItem}
            fields={this.getFields()}
            type={this.isReview() ? 'review' : 'relatedContent'}
            disabled={modalDisabled}
            handleChange={this.handleModalChange.bind(this)}
            handleCancel={this.handleModalCancel.bind(this)}
            handleSubmit={this.handleModalSubmit.bind(this)}
            assetProperties={this.assetProperties(editingItem)}
          />
        </div>
      </FormFieldWrapper>
    );
  }

  renderLocalizable() {
    const { keepEmptyFieldName, keepEmptyFieldId } = this.props;
    const { modalTitle, editingItem, modalDisabled, keepEmptyValue, items } = this.state;
    return (
      <FormFieldWrapper type="external_content">
        <div className="external-content-control">
          {!items || !items.length ? (
            <div className="row">
              <div className="read-only-list bordered">
                <div className={`col-sm-8 ${this.isReview() ? 'col-md-9 col-lg-10' : ''}`}>
                  <div className="row">
                    <ReadOnlyExternalContentList
                      value={this.shadowedValue() || []}
                      isReview={this.isReview()}
                    />
                  </div>
                </div>
                <div className={`attribute col-sm-4 ${this.isReview() ? 'col-md-3 col-lg-2' : ''}`}>
                  <FormFieldKeepEmptyElement
                    keepEmptyFieldName={keepEmptyFieldName}
                    keepEmptyFieldId={keepEmptyFieldId}
                    keepEmptyFieldValue={keepEmptyValue}
                    handleChange={this.handleKeepEmpty.bind(this)}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">{this.editableList()}</div>
          {this.newContentButton()}
          <GeneratedFormModal
            title={modalTitle}
            data={editingItem}
            fields={this.getFields()}
            type={this.isReview() ? 'review' : 'relatedContent'}
            disabled={modalDisabled}
            handleChange={this.handleModalChange.bind(this)}
            handleCancel={this.handleModalCancel.bind(this)}
            handleSubmit={this.handleModalSubmit.bind(this)}
            assetProperties={this.assetProperties(editingItem)}
          />
        </div>
      </FormFieldWrapper>
    );
  }
}

ExternalContentControl.defaultProps = {
  items: [],
  confirmRemoveMessage: 'Do you want to remove the item?',
  errorMessage: 'There has been an error in the server request',
  cantSaveEmptyMessage: "Empty items can't be saved",
  fields: [
    {
      name: 'title',
      label: 'Title',
      type: 'text',
    },
    {
      name: 'contentType',
      label: 'Type',
      type: 'select',
      options: [
        ['Press', 'press'],
        ['Social', 'social'],
        ['Award', 'award'],
      ],
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
    },
    {
      name: 'url',
      label: 'URL',
      type: 'text',
    },
    {
      name: 'image',
      label: 'Image',
      type: 'asset',
    },
  ],
};

ExternalContentControl.propTypes = {
  items: PropTypes.array.isRequired,
  contentName: PropTypes.string.isRequired,
  confirmRemoveMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  url: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  assetProperties: PropTypes.object,
};
