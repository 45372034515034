import React from 'react';
import PropTypes from 'prop-types';
import BatchStatusLabel from './BatchStatusLabel';

export default function StepStatusLabel({ step }) {
  if (!step) {
    return null;
  }
  return <BatchStatusLabel batch={step} />;
}

StepStatusLabel.propTypes = {
  step: PropTypes.shape({ state: PropTypes.string.isRequired }).isRequired,
};
