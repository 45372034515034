import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../../../util/history';
import '../../shared/navigation.sass';
import SpecsPage from './SpecsPage';

export default function BatchesRouter(): JSX.Element {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/products/specs" component={SpecsPage} />
      </Switch>
    </Router>
  );
}
