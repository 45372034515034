import React from 'react';
import PropTypes from 'prop-types';

export default class ProductAssetDelete extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDelete() {
    this.props.handleDelete(this.props.id);
  }

  render() {
    return (
      <a
        className={this.props.deleteCls}
        onClick={this.handleDelete.bind(this)}
        data-confirm={this.props.confirmStr}
      >
        <i className={this.props.iconCls} />
      </a>
    );
  }
}

ProductAssetDelete.propTypes = {
  id: PropTypes.string.isRequired,
  deleteCls: PropTypes.string,
  iconCls: PropTypes.string,
  confirmStr: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
};

ProductAssetDelete.defaultProps = {
  id: '',
  deleteCls: 'delete-product-asset',
  iconCls: 'fa fa-trash-o',
  confirmStr: I18n.t('actions.confirm'),
  handleDelete() {},
};
