import React from 'react';
import PropTypes from 'prop-types';

export default class PanelContent extends React.Component {
  getDefaultProps() {
    return {
      inputs: [],
    };
  }

  getRedirectPath() {
    const redirect = window.location.pathname.split('/edit')[0].split('/');
    redirect.pop();
    return window.location.origin + redirect.join('/');
  }

  getLabel(input) {
    return I18n.t(`activerecord.attributes.shure_product_db/translations/microphone_translation
      .${input.label}`);
  }

  setCopyAttribute(input) {
    if (input.attributeCopy) {
      return (
        <div className="attribute">
          <div className="attribute-copy">
            <i className="fa fa-clipboard" />
          </div>
        </div>
      );
    }
  }

  setInputAddOn(input) {
    if (input.attributeUnit) {
      return <div className="input-group-addon">{input.attributeUnit}</div>;
    }
  }

  setInputField(input) {
    if (input.type === 'text') {
      return (
        <input
          type={input.type}
          name={input.name}
          id={input.label}
          value={input.value}
          placeholder={input.placeholder}
          data-placeholder={input.placeholder}
          className="form-control"
        />
      );
    } else if (input.type === 'textarea' || input.type === 'richtextarea') {
      return (
        <textarea
          name={input.name}
          id={input.label}
          placeholder={input.placeholder}
          data-placeholder={input.placeholder}
          className={input.type === 'richtextarea' ? 'ckeditor form-control' : 'form-control'}
        >
          {input.value}
        </textarea>
      );
    }
  }

  render() {
    const productId = window.location.pathname.split('/')[3];
    return (
      <div className="form-horizontal">
        <input type="hidden" name="translation" id="translation" />
        <input type="hidden" name="product_id" id="product_id" value={productId} />
        {this.props.inputs.map((input, index) => (
          <div key={index} className="form-group row">
            <label className="col-xs-2" htmlFor="product_brand">
              {this.getLabel(input)}
            </label>
            <div className="col-xs-6 col-sm-8">
              <div className="input-group">
                {this.setInputField(input)}
                {this.setInputAddOn(input)}
              </div>
            </div>
            {this.setCopyAttribute(input)}
          </div>
        ))}
        <div className="form-group form-actions row">
          <div className="col-xs-10 col-xs-offset-2">
            <input
              type="submit"
              name="commit"
              value={I18n.t('inputs.save')}
              className="btn btn-default btn-primary"
            />
          </div>
          <div className="back col-xs-10 col-xs-offset-2">
            <a className="btn btn-warning" href={this.getRedirectPath()}>
              {I18n.t('links.back')}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

PanelContent.propTypes = {
  inputs: PropTypes.array.isRequired,
};
