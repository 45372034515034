import React from 'react';
import PropTypes from 'prop-types';
import APITokenField from './APITokenField';
import { showUser, createAuthorToken, deleteAuthorToken } from './usersClient';

export default class APITokenFieldContainer extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.createNewToken = this.createNewToken.bind(this);
    this.deleteToken = this.deleteToken.bind(this);
  }

  componentDidMount() {
    showUser(this.props.id).then(data => {
      this.setState({ user: data.user });
    });
  }

  createNewToken(e) {
    e.preventDefault();
    this.setState({ createTokenDisabled: true });
    createAuthorToken(this.props.id).then(data => {
      this.setState({ user: data.user, createTokenDisabled: false });
    });
  }

  deleteToken(e) {
    e.preventDefault();
    this.setState({ createTokenDisabled: true });
    deleteAuthorToken(this.props.id).then(data => {
      this.setState({ user: data.user, createTokenDisabled: false });
    });
  }

  render() {
    return (
      <APITokenField
        user={this.state.user}
        createNewToken={this.createNewToken}
        deleteToken={this.deleteToken}
        disabled={this.state.createTokenDisabled}
      />
    );
  }
}

APITokenFieldContainer.propTypes = {
  id: PropTypes.string.isRequired,
};
