import React from 'react';
import Spinner from '../../shared/Spinner';
import StepStatusLabel from './StepStatusLabel';
import StepActionLink from './StepActionLink';

function linkToPublicURL(step) {
  if (!step.pubs_public_url) {
    return null;
  }
  return (
    <a href={step.pubs_public_url} target="_blank" rel="noopener noreferrer">
      <i className="fa fa-share-square-o" aria-hidden="true" />
    </a>
  );
}

function labelStepDiscontinued(step) {
  return step.pubs_discontinued ? <span className="label label-warning">D</span> : '';
}
function labelStepMatched(step) {
  return step.is_matched ? <span className="label label-primary">M</span> : '';
}

function messageNl2Br(message) {
  return (message || '').split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
}

export default function StepsTable({ steps }) {
  if (!steps) {
    return <Spinner />;
  }
  return (
    <table className="table table-condensed table-striped">
      <thead>
        <tr>
          <th title="Document URL">URL</th>
          <th title="Matched">M</th>
          <th title="Discontinued">D</th>
          <th width="10%">Products</th>
          <th width="15%">Title</th>
          <th>Type</th>
          <th>Size</th>
          <th>Locale / Region</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody>
        {steps.map(step => (
          <tr key={step.id}>
            <td>{linkToPublicURL(step)}</td>
            <td>{labelStepMatched(step)}</td>
            <td>{labelStepDiscontinued(step)}</td>
            <td>
              {step.product_ids.map(productId => {
                return (
                  <a
                    href={`/products/discover/${productId}`}
                    key={productId}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-share-square-o" aria-hidden="true" />
                  </a>
                );
              })}
            </td>
            <td>{step.pubs_title}</td>
            <td>
              {step.pubs_type} <br />
              {step.pubs_content_type}
            </td>
            <td>{step.pubs_size}</td>
            <td>
              {step.pubs_locale} <br />
              {`"${step.pubs_region}"`}
            </td>
            <td>{messageNl2Br(step.message)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
