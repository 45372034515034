import React from 'react';
import PropTypes from 'prop-types';
import { pauseBatch, unpauseBatch } from './batchesClient';

export default class BatchActionLink extends React.Component {
  constructor(props) {
    super(props);
    this.pauseBatch = this.pauseBatch.bind(this);
    this.unpauseBatch = this.unpauseBatch.bind(this);
    this.state = {};
  }

  handleSuccess(batch) {
    this.setState({
      requestStatus: null,
    });
    this.props.updateBatchDisplay(batch);
  }

  pauseBatch() {
    this.setState({
      requestStatus: 'pending',
    });
    pauseBatch(this.props.batch).then(batch => this.handleSuccess(batch));
  }

  unpauseBatch() {
    this.setState({
      requestStatus: 'pending',
    });
    unpauseBatch(this.props.batch).then(batch => this.handleSuccess(batch));
  }

  buttonDisabled() {
    return this.state.requestStatus === 'pending';
  }

  render() {
    let label;
    let action;
    const s = this.props.batch.state;
    if (s === 'pending' || s === 'running') {
      label = 'Pause';
      action = this.pauseBatch;
    } else if (s === 'paused') {
      label = 'Resume';
      action = this.unpauseBatch;
    }

    if (!label || !action) {
      return null;
    }
    return (
      <button
        type="button"
        disabled={this.buttonDisabled()}
        onClick={action}
        className="btn btn-default"
      >
        {label}
      </button>
    );
  }
}

BatchActionLink.propTypes = {
  batch: PropTypes.shape({
    state: PropTypes.string,
  }),
  updateBatchDisplay: PropTypes.func.isRequired,
};

BatchActionLink.defaultProps = {
  batch: null,
};
