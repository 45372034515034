import React from 'react';
import PropTypes from 'prop-types';

import { Editor as TinyMceEditor } from '@tinymce/tinymce-react';

import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/code';

export default class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.setting = {
      plugins: 'link table paste lists advlist anchor code',
      toolbar: [
        'undo redo | copy paste | bold italic underline | alignleft aligncenter alignright alignnone ',
        'formatselect | numlist bullist | table | anchor link | code',
      ],
      height: this.props.height,
      content_css: window.editor_css,
      menubar: 'edit insert view format table',
      branding: false,
      skin: false,
    };
  }

  reInitalize() {
    const editorSelector = `#${this.props.id}`;
    tinymce.remove(editorSelector);
    document.getElementById(this.props.id).value = this.props.initialValue;
    tinymce.init({
      ...this.setting,
      selector: editorSelector,
      setup: editor => {
        editor.on('change', event => this.props.onChange(event));
      },
    });
  }

  resetContent() {
    tinymce.get(this.props.id).setContent(this.props.initialValue);
  }

  render() {
    return (
      <div>
        <TinyMceEditor
          init={this.setting}
          initialValue={this.props.initialValue}
          onChange={this.props.onChange}
          id={this.props.id}
        />
      </div>
    );
  }
}

Editor.defaultProps = {
  initialValue: '',
  onChange: () => null,
  height: 200,
};

Editor.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  height: PropTypes.number,
};
