import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import EditableList from '../shared/EditableList';
import ArraySelectField from './formFieldComponents/ArraySelectField';
import FormFieldWrapper from './formFieldComponents/FormFieldWrapper';
import ReadOnlySelectList from './formFieldComponents/ReadOnlySelectList';
import FieldControlBase from './formFieldComponents/FieldControlBase';

export default class ArraySelectControl extends FieldControlBase {
  constructor(props) {
    super(props);
    this.state.attributeValue = this.getInitialValue();
  }

  getInitialValue() {
    return this.props.initialValue.map(value => (Array.isArray(value) ? value[1] : value));
  }

  getAppendButton() {
    return (
      <div
        className="label label-default append-select-field"
        onClick={this.handleAppend.bind(this)}
      >
        <i className="fa fa-plus" />
      </div>
    );
  }

  getDefaultInput() {
    if (this.state.attributeValue.length < 1) {
      return <input type="hidden" name={`${this.props.fieldName}[]`} defaultValue={[]} />;
    }
  }

  getSelectOptions() {
    const options = this.props.options.map((item, optionsId) => (
      <option key={`${this.props.name}[options][${optionsId}]`} value={item[1]}>
        {item[0]}
      </option>
    ));
    return options;
  }

  updateItemsState(values) {
    this.setState({ attributeValue: values });
  }

  handleMove(index, newIndex) {
    const inputValues = this.state.attributeValue.slice();
    const [item] = inputValues.splice(index, 1);
    inputValues.splice(newIndex, 0, item);
    this.updateItemsState(inputValues);
  }

  handleAppend() {
    // Clone select with options and value
    const inputValues = this.state.attributeValue;
    let inputValue = '';
    inputValue =
      this.props.options[0] && inputValues.length < 1
        ? this.props.options[0][1]
        : inputValues.slice(-1)[0];
    inputValues.push(inputValue);
    this.updateItemsState(inputValues);
  }

  handleRemove(index) {
    const inputValues = this.state.attributeValue;
    inputValues.splice(index, 1);
    this.updateItemsState(inputValues);
  }

  handleChange(index, event) {
    const inputValues = this.state.attributeValue;
    inputValues[index] = event.target.value;
    this.updateItemsState(inputValues);
  }

  renderReadOnly() {
    return (
      <FormFieldWrapper>
        <div
          className="select-fields-area"
          data-drag-target={this.props.sortable}
          data-attr={this.props.attributeName}
        >
          <ReadOnlySelectList value={this.state.attributeValue} options={this.props.options} />
        </div>
      </FormFieldWrapper>
    );
  }

  renderRegular() {
    return (
      <FormFieldWrapper>
        <div
          className="select-fields-area"
          data-drag-target={this.props.sortable}
          data-attr={this.props.attributeName}
        >
          {this.getAppendButton()}
          {this.getDefaultInput()}
          <EditableList
            title={this.props.title}
            removable
            sortable={this.props.sortable}
            onRemove={this.handleRemove.bind(this)}
            onMove={this.handleMove.bind(this)}
          >
            {this.state.attributeValue.map((value, index) => (
              <ArraySelectField
                key={index}
                id={index}
                name={this.props.fieldName}
                value={value}
                options={this.getSelectOptions()}
                sortable={this.props.sortable}
                handleChange={this.handleChange.bind(this)}
                selectCls={this.props.selectCls}
              />
            ))}
          </EditableList>
        </div>
      </FormFieldWrapper>
    );
  }

  renderLocalizable() {
    return this.renderRegular();
  }
}

const propTypes = _.clone(FieldControlBase.propTypes);
/* eslint-disable max-len */
propTypes.initialValue = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
);
propTypes.shadowedValue = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
);
/* eslint-enable max-len */
propTypes.options = PropTypes.array;
propTypes.sortable = PropTypes.bool;
propTypes.title = PropTypes.string;
propTypes.selectCls = PropTypes.string;
ArraySelectControl.propTypes = propTypes;

ArraySelectControl.defaultProps = {
  options: [],
  sortable: false,
  title: 'selectfield',
};
