// eslint-disable-next-line import/prefer-default-export
export function readAsset(productCategory, id) {
  const showAssetUrl = `/products/${productCategory}/assets/${id}`;
  const opts = {
    headers: {
      Accept: 'application/json; charset=utf8',
    },
  };
  return fetch(showAssetUrl, opts).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Could not fetch asset');
  });
}
