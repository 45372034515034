import React, { useState, useEffect } from 'react';

import FormRow from './layout/FormRow';
import { Video, fetchVideo } from '../../apiClients/videos';

import './VideoSelectControl.scss';

type ProductTargets = 'product' | 'baseProduct' | 'variant' | 'baseVariant';

export interface VideoProp {
  id: string | null;
  path: string;
}

interface VideoSelectControlProps {
  target: ProductTargets;
  attributeName: string;
  attributeValue?: string;
  label: string;
  videos: VideoProp[];
  locale: string;
}

function formName(target: ProductTargets, attributeName: string): string {
  switch (target) {
    case 'product':
      return `product[${attributeName}]`;
    case 'baseProduct':
      return `base_product[${attributeName}]`;
    case 'variant':
      return `variant[${attributeName}]`;
    case 'baseVariant':
      return `base_variant[${attributeName}]`;
    default:
      return attributeName;
  }
}

function renderHTMLVideoElement(video: Video): JSX.Element {
  return (
    <video width="320" controls preload="metadata">
      <source src={`${video.url}#t=1.0`} type={video.contentType} />
    </video>
  );
}

function renderYouTubeVideoElement(video: Video): JSX.Element {
  return (
    <iframe
      title="YouTube Video Preview"
      src={`https://www.youtube-nocookie.com/embed/${video.youTubeID}`}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
    />
  );
}

function videoPreview(video: Video | undefined): JSX.Element | null {
  if (video && video.contentType && video.url) {
    return renderHTMLVideoElement(video);
  }
  if (video && video.youTubeID) {
    return renderYouTubeVideoElement(video);
  }
  return null;
}

function videoPreviewContainer(selectValue?: string, video?: Video): JSX.Element | null {
  if (!selectValue) {
    return null;
  }
  return <div className="video-preview-container">{videoPreview(video)}</div>;
}

const VideoSelectControl = ({
  target,
  attributeName,
  attributeValue,
  label,
  videos,
  locale,
}: VideoSelectControlProps): JSX.Element => {
  const [selectValue, setSelectValue] = useState(attributeValue);
  const [video, setVideo] = useState<Video | undefined>(undefined);

  async function handleVideo(id: string): Promise<void> {
    const v = await fetchVideo(locale, id);
    setVideo(v || undefined);
  }

  useEffect(() => {
    setVideo(undefined);
    if (selectValue) {
      handleVideo(selectValue);
    }
  }, [selectValue]);

  function handleChange(e: React.FormEvent<HTMLSelectElement>): void {
    setSelectValue(e.currentTarget.value);
  }

  function valueForHidden(): string {
    return selectValue || '';
  }

  return (
    <FormRow label={label} attributeName={attributeName}>
      <div>
        {videoPreviewContainer(selectValue, video)}
        <div>
          <input type="hidden" name={formName(target, attributeName)} value={valueForHidden()} />
          <select className="col-sm-5" value={selectValue || ''} onChange={handleChange}>
            {videos.map(v => (
              <option key={v.id || ''} value={v.id || ''}>
                {v.path}
              </option>
            ))}
          </select>
        </div>
      </div>
    </FormRow>
  );
};

export default VideoSelectControl;
