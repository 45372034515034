export interface Category {
  name: string;
  key: string;
  singular: string;
}

const url = '/products/categories.json';

async function fetchCategories(): Promise<Category[]> {
  const response = await fetch(url);
  if (response.status !== 200) {
    throw new Error(`Error fetching categories: ${response.statusText}`);
  }
  const json = await response.json();
  const categories = json.map(cat => {
    const category: Category = {
      name: cat.name,
      key: cat.key,
      singular: cat.singular,
    };
    return category;
  });
  return categories;
}

// eslint-disable-next-line import/prefer-default-export
export { fetchCategories };
