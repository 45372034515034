import React from 'react';
import PropTypes from 'prop-types';

export default class ArraySelectField extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange(index, event) {
    this.props.handleChange(index, event);
  }

  render() {
    return (
      <select
        className={this.props.selectCls}
        name={`${this.props.name}[]`}
        value={this.props.value}
        onChange={this.handleChange.bind(this, this.props.id)}
      >
        {this.props.options}
      </select>
    );
  }
}

ArraySelectField.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multiselectCls: PropTypes.string,
  selectCls: PropTypes.string,
  getDragAttribute: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
};

ArraySelectField.defaultProps = {
  id: 0,
  value: '',
  name: '',
  options: [],
  multiselectCls: 'multiselect-field clearfix',
  selectCls: 'col-sm-5',
  getDragAttribute() {},
  handleChange() {},
};
